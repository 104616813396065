main.Screen[custom-agua-item="screen"] {
    background: var(--bgColor) ; 
}
section.main[custom-agua-item="layer"] {
    
overflow-y: scroll ;
scrollbar-width: none ;
-ms-overflow-style: none ;
min-height: unset;
height: 100%;

min-width: unset;
width: 100%;

position: absolute;
z-index: 2;
}
.hiddenoverflowx {
    overflow-x: hidden;
    white-space: nowrap !important;
}
section.galaxybg[custom-agua-item="layer"] {
    

min-height: unset;
height: 100%;

min-width: unset;
width: 100%;

position: absolute;
z-index: 0;
}
.galaxybg {
    background-image: url('https://uploads-ssl.webflow.com/6408bea3de5aef58b7e197d4/6438c3059d443cd7a78762eb_stars-bg.png') !important;
    background-position: 50%;
    background-size: 1090px;
    position: absolute;
    top: 0%;
    bottom: 0%;
    left: 0%;
    right: 0%;
    overflow: hidden;
}
section.header[custom-agua-item="layer"] {
    

min-height:  unset ;height: fit-content ;
min-width: unset;
width: 100%;

position: relative;
}
section.layer-228-5baceea8570045fca67c3a84a6bcbe6e[custom-agua-item="layer"] {
    flex-direction: row ;
align-items: center ;
justify-content: center ;

background-color: #1565C0 ;
height: 25px ;min-height: 25px ;
min-width: unset;
width: 100%;

}
p.text-95-8cfaebf5ef6e43ab9b35060b25738113[custom-agua-item="text"] {
     
font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Roboto', sans-serif ;
font-size: 14px ;


background: #FFFFFF ;


}
p.text-96-ff0637b13dad4eab995223dbc1f570bb[custom-agua-item="text"] {
     
font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Roboto', sans-serif ;
font-size: 14px ;
font-weight: bold ;

cursor: pointer;
margin-left: 6px ;
box-sizing: border-box ;
background: #FFFFFF ;


}
section.layer-229-53ded678cce340be8409af0eda3f5480[custom-agua-item="layer"] {
    flex-direction: row ;
align-items: center ;
justify-content: space-between ;

margin-top: 16px ;
box-sizing: border-box ;
min-height:  unset ;height: fit-content ;
min-width: unset;
width: 100%;
padding-left: var(--53ded678cce340be8409af0eda3f5480_padding-left) ; 
padding-right: var(--53ded678cce340be8409af0eda3f5480_padding-right) ; 
}
section.layer-230-d0ae8f4b5a104c71ba051257f05dd7f1[custom-agua-item="layer"] {
    

min-height:  unset ;height: fit-content ;
min-width:  unset ;width: fit-content ;
}
.imageview-21-78f69d1d417440e2b88db0edd6cc2325[custom-agua-item="imageView"] {
    min-width: var(--min-78f69d1d417440e2b88db0edd6cc2325_valuewidth) ; 
width: var(--78f69d1d417440e2b88db0edd6cc2325_valuewidth); 
min-height: var(--min-78f69d1d417440e2b88db0edd6cc2325_valueheight) ; 
height: var(--78f69d1d417440e2b88db0edd6cc2325_valueheight); 
}
section.layer-231-f9ef7730b5294bbcb34ce628df3a5301[custom-agua-item="layer"] {
    flex-direction: row ;

min-height:  unset ;height: fit-content ;
min-width:  unset ;width: fit-content ;
}
p.text-91-a49beb47a54f4916ab82e367c50651f0[custom-agua-item="text"] {
     
font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Roboto', sans-serif ;

font-weight: bold ;

cursor: pointer;
background: #FFFFFF ;


}
p.text-92-d8b204606525467184e5e09d190e8e50[custom-agua-item="text"] {
     
font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Roboto', sans-serif ;

font-weight: bold ;

cursor: pointer;
margin-left: 32px ;
box-sizing: border-box ;
background: #FFFFFF ;


}
section.spacertop[custom-agua-item="layer"] {
    

height: 1px ;min-height: 1px ;
min-width: unset;
width: 100%;
margin-top: var(--spacertop_margin-top) ; 
}
section.hero[custom-agua-item="layer"] {
    
justify-content: center ;
align-items: center ;

min-height:  unset ;height: fit-content ;
min-width: unset;
width: 100%;

position: relative;
}
section.heroheader[custom-agua-item="layer"] {
    flex-direction: row ;
align-items: center ;
justify-content: center ;

min-height:  unset ;height: fit-content ;
min-width:  unset ;width: fit-content ;
}
section.spacer1[custom-agua-item="layer"] {
    

height: 1px ;min-height: 1px ;
width: 50px ;min-width: 50px ;background: var(--fontTitleColor) ; 
}
p.text-2-de0ef3ff99d94ff992cc127eaec0bfb7[custom-agua-item="text"] {
     
font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Roboto', sans-serif ;


margin-left: 32px ;
margin-right: 32px ;
box-sizing: border-box ;

background: var(--fontTitleColor) ; 
background: var(--fontTitleColor) ; 
font-size: var(--fontSmallTitleSize) ; 
}
section.spacer2[custom-agua-item="layer"] {
    

height: 1px ;min-height: 1px ;
width: 50px ;min-width: 50px ;background: var(--fontTitleColor) ; 
}
section.herotitle[custom-agua-item="layer"] {
    
justify-content: center ;
align-items: center ;

min-height:  unset ;height: fit-content ;
min-width:  unset ;width: fit-content ;
}
p.text-1-59c9b326fe464f5c8c5751b1c61fb97b[custom-agua-item="text"] {
     
font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Roboto', sans-serif ;
font-weight: bold ;

background: #FFFFFF ;

font-size: var(--fontTitleSize) ; 
}
section.herosubtitle[custom-agua-item="layer"] {
    
justify-content: center ;
align-items: center ;

margin-top: 32px ;
box-sizing: border-box ;
min-height:  unset ;height: fit-content ;
min-width:  unset ;width: fit-content ;
}
p.text-3-952c4fa5f53349569892cdb440d314c4[custom-agua-item="text"] {
     text-align: center ;
font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Roboto', sans-serif ;



width: 450px ;min-width: 450px ;background: var(--fontTitleColor) ; 
background: var(--fontTitleColor) ; 
font-size: var(--fontMediumTitleSize) ; 
}
p.text-14-844722d3149340a299a72ab40e5ba90d[custom-agua-item="text"] {
     
font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Roboto', sans-serif ;



background: var(--fontTitleColor) ; 
background: var(--fontTitleColor) ; 
font-size: var(--fontMediumTitleSize) ; 
}
section.herobgimage[custom-agua-item="layer"] {
    
margin-top: 48px ;
box-sizing: border-box ;background-repeat: no-repeat ;
background-size: cover ;
background-position: center ;
background-image: url('../../../assets/heroImage.png') ;
height: 46vw ;min-height: 46vw ;
width: 90vw ;min-width: 90vw ;border-radius: var(--herobgimage_radiusAbsolute) ; 
}
.hidden {
    overflow: hidden;
}
section.maincta[custom-agua-item="layer"] {
    
justify-content: center ;
align-items: center ;

padding-top: 30vh ;
box-sizing: border-box ;
background: linear-gradient(180deg, rgba(6,5,12,1) 0%, rgba(3,0,19,1) 80%) ;
min-height:  unset ;height: fit-content ;
min-width: unset;
width: 100%;

position: relative;
}
section.bgfooter[custom-agua-item="layer"] {
    

height: 50% ;min-height: 50% ;
position: absolute;min-width: var(--min-bgfooter_valuewidth) ; 
width: var(--bgfooter_valuewidth); 
}
.imageview-3-697acd903bfe4767ac1ba9ca3ff6bf0b[custom-agua-item="imageView"] {
    
min-height: unset;
height: 100%;

min-width: unset;
width: 100%;

}
section.titlefooter[custom-agua-item="layer"] {
    
justify-content: center ;
align-items: center ;

min-height:  unset ;height: fit-content ;
min-width:  unset ;width: fit-content ;
position: relative;
}
p.text-10-b23bfe3f477d4c4f93493312a61ac63b[custom-agua-item="text"] {
     
font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Roboto', sans-serif ;


background: #FFFFFF ;

font-size: var(--fontTitleLarge) ; 
}
section.layer-25-37772eeb47c649d8a7633a7db8391808[custom-agua-item="layer"] {
    flex-direction: row ;

min-height:  unset ;height: fit-content ;
min-width:  unset ;width: fit-content ;
}
p.text-11-b2a93e5e6c204eacbe43825a5d37ffd2[custom-agua-item="text"] {
     
font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Roboto', sans-serif ;


margin-right: 10px ;
box-sizing: border-box ;
background: #FFFFFF ;

font-size: var(--fontTitleLarge) ; 
}
p.text-9-7e5c6237c9b949ad9e8f6ff1fd7e9c5f[custom-agua-item="text"] {
     
font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Roboto', sans-serif ;



background: var(--gradientColor1) ; 
background: var(--gradientColor1) ; 
font-size: var(--fontTitleLarge) ; 
}
section.ctafooter[custom-agua-item="layer"] {
    
--verticalAxis-ctafooter: center;
--horizontalAxis-ctafooter: center;

margin-top: 32px ;
box-sizing: border-box ;
min-height:  unset ;height: fit-content ;
min-width:  unset ;width: fit-content ;
position: relative;flex-direction: var(--ctafooter_orientation) ; 
}
section.bookcall[custom-agua-item="layer"] {
    flex-direction: row ;
cursor: pointer;
align-items: center ;
justify-content: center ;
border: 1px solid #BA68C8;
border-radius: 6px ;
padding-top: 8px ;
padding-bottom: 8px ;
padding-left: 16px ;
padding-right: 16px ;
box-sizing: border-box ;
min-height:  unset ;height: fit-content ;
min-width:  unset ;width: fit-content ;
}
p.text-12-d06b544ebfd540e3b49b93d46717c92d[custom-agua-item="text"] {
     
font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Roboto', sans-serif ;



background: #FFFFFF ;


}
.icon-1-686f9ffa4b68458b821f54fe7c89a44e[custom-agua-item="icon"] {
    
margin-left: 8px ;
box-sizing: border-box ;
background: #FEFEFE ;
height: 20px ;min-height: 20px ;
width: 20px ;min-width: 20px ; font-size: 20px ;
}
section.joincommunity[custom-agua-item="layer"] {
    flex-direction: row ;
cursor: pointer;
align-items: center ;
justify-content: center ;

min-height:  unset ;height: fit-content ;
min-width:  unset ;width: fit-content ;margin-left: var(--joincommunity_margin-left) ; 
margin-top: var(--joincommunity_margin-top) ; 
}
p.text-13-eecb5986468649b79c48fae96e00708a[custom-agua-item="text"] {
     
font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Roboto', sans-serif ;



background: #FFFFFF ;


}
.icon-2-c7512d2e234f43d1895abf0f5f6d8385[custom-agua-item="icon"] {
    
margin-left: 10px ;
box-sizing: border-box ;
background: #FEFEFE ;
height: 20px ;min-height: 20px ;
width: 20px ;min-width: 20px ; font-size: 20px ;
}
section.container[custom-agua-item="layer"] {
    

background-color: #000000 ;
min-height:  unset ;height: fit-content ;
min-width: unset;
width: 100%;

position: relative;padding-bottom: var(--container_padding-bottom) ; 
}
section.features[custom-agua-item="layer"] {
    
align-items: center ;

background-color: #000000 ;
min-height:  unset ;height: fit-content ;
min-width: unset;
width: 100%;

position: relative;
}
section.galaxybgtwo[custom-agua-item="layer"] {
    

min-height: unset;
height: 100%;

min-width: unset;
width: 100%;

position: absolute;
z-index: 4;
}
section.imagefeaturescontainer[custom-agua-item="layer"] {
    
align-items: center ;

position: relative;background: var(--bgColor2) ; 
min-height: var(--min-imagefeaturescontainer_valueheight) ; 
height: var(--imagefeaturescontainer_valueheight); 
min-width: var(--min-imagefeaturescontainer_valuewidth) ; 
width: var(--imagefeaturescontainer_valuewidth); 
}
section.layer-238-a084123826ce44a08da5b4692e640631[custom-agua-item="layer"] {
    

min-height: unset;
height: 100%;

min-width: unset;
width: 100%;

position: relative;
}
.imageview-5-5c960ff1548f424ab71d6da58f0fb006[custom-agua-item="imageView"] {
    
height: 53vw ;min-height: 53vw ;
width: 100vw ;min-width: 100vw ;
}
section.layer-237-766c4a2f4a13457c8a4dfc5319e08454[custom-agua-item="layer"] {
    

background: linear-gradient(180deg, rgba(0,0,0,0) 20%, rgba(0,0,0,1) 100%) ;
min-height: unset;
height: 100%;

min-width: unset;
width: 100%;

position: absolute;
}
section.featurescontent[custom-agua-item="layer"] {
    

padding-bottom: 64px ;
box-sizing: border-box ;
background-color: #000000 ;
min-height:  unset ;height: fit-content ;
min-width: unset;
width: 100%;

position: relative;margin-top: var(--featurescontent_margin-top) ; 
}
.negmargintop2 {
    top: -100px;
}
section.featuresmaintitle[custom-agua-item="layer"] {
    
justify-content: center ;
align-items: center ;

min-height:  unset ;height: fit-content ;
min-width: unset;
width: 100%;

}
p.text-19-5996d69b06dc4661926a8a821fa1a661[custom-agua-item="text"] {
     
font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Roboto', sans-serif ;


background: #FFFFFF ;

font-size: var(--fontTitleXLargeSize) ; 
}
p.text-20-6f6005ecd0be449699ab9182225dd09a[custom-agua-item="text"] {
     
font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Roboto', sans-serif ;


background: #FFFFFF ;

font-size: var(--fontTitleXLargeSize) ; 
}
section.horizontalcard0[custom-agua-item="layer"] {
    

min-height:  unset ;height: fit-content ;
min-width: unset;
width: 100%;
padding-left: var(--marginSidesContent) ; 
padding-right: var(--marginSidesContent) ; 
padding-top: var(--marginSidesContent) ; 
}
section.cardhor0[custom-agua-item="layer"] {
    
border-color: #222222;
border-radius: 20px ;
height: 450px ;min-height: 450px ;
min-width: unset;
width: 100%;

position: relative;
z-index: 4;background: var(--cardColor) ; 
}
section.featurecards1[custom-agua-item="layer"] {
    
--verticalAxis-featurecards1: center;
--horizontalAxis-featurecards1: space-between;

min-height:  unset ;height: fit-content ;
min-width: unset;
width: 100%;

z-index: 4;padding-right: var(--marginSidesContent) ; 
flex-direction: var(--featurecards1_orientation) ; 
padding-left: var(--featurecards1_padding-left) ; 
margin-top: var(--featurecards1_margin-top) ; 
}
section.card1menu[custom-agua-item="layer"] {
    

min-width: unset;
width: calc(100% - var(--card1menu_margin-left));

position: relative;min-height: var(--min-smallCardHeight) ; 
height: var(--smallCardHeight); 
align-items: var(--card1menu_horizontalAlignment) ; 
justify-content: var(--card1menu_verticalAlignment) ; 
margin-left: var(--card1menu_margin-left) ; 
background: var(--card1menu_backgroundColor) ; 
}
section.layer-163-ea5a4579043e45f5969fc36cbb468631[custom-agua-item="layer"] {
    

min-width: unset;
width: 100%;

position: absolute;min-height: var(--min-smallCardHeight) ; 
height: var(--smallCardHeight); 
display: var(--ea5a4579043e45f5969fc36cbb468631_visible) ; 
background: var(--ea5a4579043e45f5969fc36cbb468631_backgroundColor) ; 
}
section.layer-164-da6a28df823a420eb39081269d975369[custom-agua-item="layer"] {
    
align-items: center ;
border-radius: 12px ;
padding-top: 16px ;
padding-bottom: 16px ;
padding-left: 32px ;
padding-right: 32px ;
box-sizing: border-box ;
position: absolute;
z-index: 10;min-height: var(--min-smallCardHeight) ; 
height: var(--smallCardHeight); 
min-width: var(--min-da6a28df823a420eb39081269d975369_valuewidth) ; 
width: var(--da6a28df823a420eb39081269d975369_valuewidth); 
}
.collaborationimg[custom-agua-item="imageView"] {
    
height: 200px ;min-height: 200px ;
width: 200px ;min-width: 200px ;
}
p.text-27-35265a6854084a94894c7a1da86d27e4[custom-agua-item="text"] {
     
font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Roboto', sans-serif ;
font-weight: bold ;

margin-top: 24px ;
box-sizing: border-box ;
background: #FFFFFF ;

font-size: var(--fontMediumTitleSize) ; 
}
p.text-28-10b1968fafc24af69b91d41fcb7dbf7c[custom-agua-item="text"] {
     text-align: center ;
font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Roboto', sans-serif ;


margin-top: 16px ;
box-sizing: border-box ;
background: #FFFFFF ;

min-width: unset;
width: calc(100% - calc(var(--10b1968fafc24af69b91d41fcb7dbf7c_margin-left) + var(--10b1968fafc24af69b91d41fcb7dbf7c_margin-right)));
font-size: var(--fontSizeRegular) ; 
margin-left: var(--10b1968fafc24af69b91d41fcb7dbf7c_margin-left) ; 
margin-right: var(--10b1968fafc24af69b91d41fcb7dbf7c_margin-right) ; 
}
section.card2menu[custom-agua-item="layer"] {
    

min-width: unset;
width: calc(100% - var(--card2menu_margin-left));

position: relative;min-height: var(--min-smallCardHeight) ; 
height: var(--smallCardHeight); 

--margin-top: var(--card2menu_margin-top);
margin-top: var(--margin-top) ;

--margin-bottom: var(--card2menu_margin-bottom);
margin-bottom: var(--margin-bottom) ;
align-items: var(--card2menu_horizontalAlignment) ; 
justify-content: var(--card2menu_verticalAlignment) ; 
margin-left: var(--card2menu_margin-left) ; 
}
section.layer-250-433545bdefdd42d991a3af6803271662[custom-agua-item="layer"] {
    

min-height: unset;
height: 100%;

min-width: unset;
width: 100%;

position: absolute;display: var(--433545bdefdd42d991a3af6803271662_visible) ; 
}
section.layer-251-5b547c317a9c45848f2c97a9a08cadc8[custom-agua-item="layer"] {
    
align-items: center ;
border-radius: 12px ;
padding-top: 16px ;
padding-bottom: 16px ;
padding-left: 32px ;
padding-right: 32px ;
box-sizing: border-box ;
position: absolute;
z-index: 10;min-height: var(--min-5b547c317a9c45848f2c97a9a08cadc8_valueheight) ; 
height: var(--5b547c317a9c45848f2c97a9a08cadc8_valueheight); 
min-width: var(--min-5b547c317a9c45848f2c97a9a08cadc8_valuewidth) ; 
width: var(--5b547c317a9c45848f2c97a9a08cadc8_valuewidth); 
}
section.layer-103-97f49bb246714c2f9a5d623b7d98c3e1[custom-agua-item="layer"] {
    
justify-content: center ;

height: 200px ;min-height: 200px ;
width: 230px ;min-width: 230px ;align-items: var(--97f49bb246714c2f9a5d623b7d98c3e1_horizontalAlignment) ; 
}
p.text-31-c9a83cff479c43e697fd9977cb025aab[custom-agua-item="text"] {
     
font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Roboto', sans-serif ;
font-weight: bold ;

background: #A2A2A2 ;

font-size: var(--fontMediumTitleSize) ; 
}
section.layer-104-3f42a8a4563645fda7a0dea1e8e61751[custom-agua-item="layer"] {
    flex-direction: row ;

min-height:  unset ;height: fit-content ;
min-width:  unset ;width: fit-content ;
}
p.text-32-a0086829842d47708729f44da6f792ce[custom-agua-item="text"] {
     
font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Roboto', sans-serif ;
font-weight: bold ;

background: #A2A2A2 ;

font-size: var(--fontMediumTitleSize) ; 
}
p.text-33-a8d197efbcbe47468aa61089f0e01ffe[custom-agua-item="text"] {
     
font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Roboto', sans-serif ;
font-weight: bold ;

margin-left: 10px ;
box-sizing: border-box ;
background: linear-gradient(90deg,#a594fd 0,#eb8bf7) ;

font-size: var(--fontMediumTitleSize) ; 
}
p.text-34-edea5b9392aa498e978c3d991ecc76f9[custom-agua-item="text"] {
     
font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Roboto', sans-serif ;
font-weight: bold ;

background: #A2A2A2 ;

font-size: var(--fontMediumTitleSize) ; 
}
p.text-29-840cd21dd2684d5c87680dcc47b109d8[custom-agua-item="text"] {
     
font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Roboto', sans-serif ;
font-weight: bold ;

margin-top: 24px ;
box-sizing: border-box ;
background: #FFFFFF ;

font-size: var(--fontMediumTitleSize) ; 
}
p.text-30-fa709c781c564ad7a917fd694e87fa0c[custom-agua-item="text"] {
     text-align: center ;
font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Roboto', sans-serif ;


margin-top: 16px ;
box-sizing: border-box ;
background: #FFFFFF ;

min-width: unset;
width: calc(100% - calc(var(--fa709c781c564ad7a917fd694e87fa0c_margin-left) + var(--fa709c781c564ad7a917fd694e87fa0c_margin-right)));
font-size: var(--fontSizeRegular) ; 
margin-left: var(--fa709c781c564ad7a917fd694e87fa0c_margin-left) ; 
margin-right: var(--fa709c781c564ad7a917fd694e87fa0c_margin-right) ; 
}
.normalwhitespace {
    white-space: normal !important;
}
section.card3menu1[custom-agua-item="layer"] {
    

min-width: unset;
width: calc(100% - var(--card3menu1_margin-left));

position: relative;min-height: var(--min-smallCardHeight) ; 
height: var(--smallCardHeight); 
margin-left: var(--card3menu1_margin-left) ; 
}
section.layer-178-2f43cbedaf8e4f5481fd0deb94739f6b[custom-agua-item="layer"] {
    
min-height: var(--min-2f43cbedaf8e4f5481fd0deb94739f6b_valueheight) ; 
height: var(--2f43cbedaf8e4f5481fd0deb94739f6b_valueheight); 
min-width: var(--min-2f43cbedaf8e4f5481fd0deb94739f6b_valuewidth) ; 
width: var(--2f43cbedaf8e4f5481fd0deb94739f6b_valuewidth); 
display: var(--2f43cbedaf8e4f5481fd0deb94739f6b_visible) ; 
}
section.layer-179-4955e12c96ec4639966207fa2aa6f555[custom-agua-item="layer"] {
    
border-radius: 12px ;
min-height: unset;
height: 100%;

min-width: unset;
width: 100%;

position: absolute;align-items: var(--4955e12c96ec4639966207fa2aa6f555_horizontalAlignment) ; 
justify-content: var(--4955e12c96ec4639966207fa2aa6f555_verticalAlignment) ; 
}
section.horizontalcard1[custom-agua-item="layer"] {
    

min-height:  unset ;height: fit-content ;
min-width: unset;
width: 100%;
padding-left: var(--marginSidesContent) ; 
padding-right: var(--marginSidesContent) ; 
padding-top: var(--horizontalcard1_padding-top) ; 
}
section.cardhor1[custom-agua-item="layer"] {
    
border-color: #222222;
border-radius: 20px ;
height: 450px ;min-height: 450px ;
min-width: unset;
width: 100%;

position: relative;
z-index: 4;background: var(--cardColor2) ; 
}
section.featurecards2[custom-agua-item="layer"] {
    
justify-content: center ;
align-items: center ;

min-height:  unset ;height: fit-content ;
min-width: unset;
width: 100%;

z-index: 4;padding-top: var(--featurecards2_padding-top) ; 
}
section.card3[custom-agua-item="layer"] {
    flex-direction: row ;
border-color: #222222;
border-radius: 20px ;
min-height:  unset ;height: fit-content ;
min-width: unset;
width: calc(100% - calc(var(--card3_margin-left) + var(--card3_margin-right)));

position: relative;padding-left: var(--marginSidesContent) ; 
margin-left: var(--card3_margin-left) ; 
margin-right: var(--card3_margin-right) ; 
}
section.branch1[custom-agua-item="layer"] {
    
justify-content: center ;
align-items: center ;

min-height: unset;
height: 100%;

width: 30px ;min-width: 30px ;margin-left: var(--marginSidesContent) ; 
}
section.prebranch1[custom-agua-item="layer"] {
    

margin-bottom: 10px ;
box-sizing: border-box ;
background: linear-gradient(transparent, #C864AA 10%, #e5abd4, #e5abd4, #C864AA 90%, transparent) ;
min-height: unset;
height: calc(100% - 10px);

width: 2px ;min-width: 2px ;
}
section.innericonglow1[custom-agua-item="layer"] {
    

min-height:  unset ;height: fit-content ;
min-width:  unset ;width: fit-content ;
position: relative;
}
section.layer-605-961fae132b814a0f850ec7c2de849919[custom-agua-item="layer"] {
    

background-color: #f778ba ;
height: 30px ;min-height: 30px ;
width: 30px ;min-width: 30px ;
position: absolute;
}
.glowbgcircle {
    filter: blur(18px);
}
section.layer-493-6d5890e16ad340f78b17c2729268b0f8[custom-agua-item="layer"] {
    

min-height:  unset ;height: fit-content ;
min-width:  unset ;width: fit-content ;
position: relative;
}
.icon-13-ecd26cc210c4446193c03ca6cc685028[custom-agua-item="icon"] {
    
background: #FCFCFC ;
height: 25px ;min-height: 25px ;
width: 25px ;min-width: 25px ; font-size: 25px ;
}
.glowbg {
    background-color: #f778ba;
    filter: blur(18px);
}
section.posbranch1[custom-agua-item="layer"] {
    

margin-top: 10px ;
box-sizing: border-box ;
background: linear-gradient(transparent, #C864AA 10%, #e5abd4, #e5abd4, #C864AA 90%, transparent) ;
min-height: unset;
height: calc(100% - calc(10px + 0px));

width: 2px ;min-width: 2px ;
}
section.card3container[custom-agua-item="layer"] {
    flex-direction: row ;
align-items: center ;
justify-content: space-between ;

margin-bottom: 96px ;
box-sizing: border-box ;
min-height:  unset ;height: fit-content ;
min-width: unset;
width: calc(100% - var(--halfMarginSidesContent));
padding-right: var(--marginSidesContent) ; 
margin-left: var(--halfMarginSidesContent) ; 
flex-wrap: var(--card3container_wrap) ; 
}
section.contentcard3[custom-agua-item="layer"] {
    

margin-top: 96px ;
box-sizing: border-box ;
min-height: unset;
height: calc(100% - calc(96px + 0px));

min-width:  unset ;width: fit-content ;
}
section.line1card3[custom-agua-item="layer"] {
    flex-direction: row ;

min-height:  unset ;height: fit-content ;
min-width:  unset ;width: fit-content ;margin-top: var(--line1card3_margin-top) ; 
}
p.focusboldcard3[custom-agua-item="text"] {
     
font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Roboto', sans-serif ;
font-weight: bold ;

background: #FFFFFF ;

font-size: var(--fontMediumTitleSize) ; 
}
p.text-163-1f493a2b0a644c77bd2c2825a68c29e4[custom-agua-item="text"] {
     
font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Roboto', sans-serif ;


background: #B9B9B9 ;

font-size: var(--fontMediumTitleSize) ; 
margin-left: var(--1f493a2b0a644c77bd2c2825a68c29e4_margin-left) ; 
}
p.line2card3[custom-agua-item="text"] {
     
font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Roboto', sans-serif ;


background: #B9B9B9 ;

font-size: var(--fontMediumTitleSize) ; 
}
p.line3card3[custom-agua-item="text"] {
     
font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Roboto', sans-serif ;


background: #B9B9B9 ;

font-size: var(--fontMediumTitleSize) ; 
}
p.line4card3[custom-agua-item="text"] {
     
font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Roboto', sans-serif ;
font-weight: bold ;

background: linear-gradient(50deg,#714dff,#9c83ff 31.28%,#e151ff 77.97%,#fff759 95.64%) ;

font-size: var(--fontMediumTitleSize) ; 
}
section.didyouknow1[custom-agua-item="layer"] {
    
border-color: #C864AA;
border-radius: 200px ;
margin-top: 64px ;
box-sizing: border-box ;
min-height:  unset ;height: fit-content ;
min-width:  unset ;width: fit-content ;
}
p.text-47-1511a90f2f8f407ab6f879ce22a1a5b6[custom-agua-item="text"] {
     
font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Roboto', sans-serif ;
font-weight: bold ;

background: #C864AA ;

font-size: var(--fontSmallTitleSize) ; 
}
p.faster5x[custom-agua-item="text"] {
     
font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Roboto', sans-serif ;


background: #C864AA ;

font-size: var(--fontTitleLarge) ; 
}
p.line5card3[custom-agua-item="text"] {
     
font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Roboto', sans-serif ;


background: #FFFFFF ;

font-size: var(--fontSizeRegular) ; 
}
p.line6card3[custom-agua-item="text"] {
     
font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Roboto', sans-serif ;


background: #FFFFFF ;

font-size: var(--fontSizeRegular) ; 
}
section.card3image[custom-agua-item="layer"] {
    
align-items: flex-end ;
border-color: #222222;
border-radius: 10px ;
margin-top: 64px ;
box-sizing: border-box ;
min-height:  unset ;height: fit-content ;
min-width:  unset ;width: fit-content ;
}
.imageview-11-1a0531d15e4748e0a10c485b417a4def[custom-agua-item="imageView"] {
    min-width: var(--min-1a0531d15e4748e0a10c485b417a4def_valuewidth) ; 
width: var(--1a0531d15e4748e0a10c485b417a4def_valuewidth); 
min-height: var(--min-1a0531d15e4748e0a10c485b417a4def_valueheight) ; 
height: var(--1a0531d15e4748e0a10c485b417a4def_valueheight); 

--margin-left: var(--1a0531d15e4748e0a10c485b417a4def_margin-left);
margin-left: var(--margin-left) ;

--margin-right: var(--1a0531d15e4748e0a10c485b417a4def_margin-right);
margin-right: var(--margin-right) ;
}
section.horizontalcard2[custom-agua-item="layer"] {
    

min-height:  unset ;height: fit-content ;
min-width: unset;
width: 100%;
padding-left: var(--marginSidesContent) ; 
padding-right: var(--marginSidesContent) ; 
}
section.card4[custom-agua-item="layer"] {
    
border-color: #222222;
border-radius: 20px ;
height: 450px ;min-height: 450px ;
position: relative;
z-index: 4;background: var(--cardColor2) ; 
min-width: var(--min-card4_valuewidth) ; 
width: var(--card4_valuewidth); 
}
section.featurecards4[custom-agua-item="layer"] {
    

min-height:  unset ;height: fit-content ;
min-width: unset;
width: 100%;

position: relative;
}
section.bgfadetoblack[custom-agua-item="layer"] {
    

background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 80%) ;
min-height: unset;
height: 100%;

min-width: unset;
width: 100%;

position: absolute;
}
section.twocolumnscards[custom-agua-item="layer"] {
    

min-height: unset;
height: 100%;

min-width: unset;
width: 100%;

position: relative;padding-left: var(--marginSidesContent) ; 
padding-right: var(--twocolumnscards_padding-right) ; 
padding-top: var(--twocolumnscards_padding-top) ; 
flex-direction: var(--twocolumnscards_orientation) ; 
}
section.card7[custom-agua-item="layer"] {
    
border-color: #222222;
border-radius: 20px ;
height: 800px ;min-height: 800px ;
min-width: unset;
width: calc(100% - var(--card7_margin-right));

position: relative;
z-index: 4;background: var(--cardColor2) ; 
margin-right: var(--card7_margin-right) ; 
}
section.card8[custom-agua-item="layer"] {
    
border-color: #222222;
border-radius: 20px ;
height: 800px ;min-height: 800px ;
min-width: unset;
width: calc(100% - var(--card8_margin-right));

position: relative;
z-index: 4;background: var(--cardColor2) ; 
margin-right: var(--card8_margin-right) ; 
margin-top: var(--card8_margin-top) ; 
}
section.featurecards5[custom-agua-item="layer"] {
    

min-height:  unset ;height: fit-content ;
min-width: unset;
width: 100%;

position: relative;padding-left: var(--marginSidesContent) ; 
flex-direction: var(--featurecards5_orientation) ; 
padding-right: var(--featurecards5_padding-right) ; 
}
section.layer-273-dbd6ee8c5207457bb7d8cf43b6072fc3[custom-agua-item="layer"] {
    flex-direction: row ;

height: 860px ;min-height: 860px ;
min-width: unset;
width: calc(100% - var(--halfMarginSidesContent));
margin-right: var(--halfMarginSidesContent) ; 
}
section.branch3[custom-agua-item="layer"] {
    
justify-content: center ;
align-items: center ;

min-height: unset;
height: 100%;

min-width:  unset ;width: fit-content ;margin-left: var(--marginSidesContent) ; 
}
section.prebranch3[custom-agua-item="layer"] {
    

margin-bottom: 12px ;
box-sizing: border-box ;
background: linear-gradient(transparent, rgb(51, 179, 174) 40%) ;
min-height: unset;
height: calc(100% - 12px);

width: 2px ;min-width: 2px ;
}
section.innericonglow[custom-agua-item="layer"] {
    
justify-content: center ;
align-items: center ;

min-height:  unset ;height: fit-content ;
min-width:  unset ;width: fit-content ;
position: relative;
}
section.layer-1138-fac66e59597a47278845ce0b62d9df2a[custom-agua-item="layer"] {
    

background-color: #41e4de ;
height: 30px ;min-height: 30px ;
width: 30px ;min-width: 30px ;
position: absolute;
}
section.layer-1139-334228343227478186313ac409a054ea[custom-agua-item="layer"] {
    

min-height:  unset ;height: fit-content ;
min-width:  unset ;width: fit-content ;
position: relative;
}
.icon-64-58b06f25f78d4939a7f2a1c7a5dc812d[custom-agua-item="icon"] {
    
background: #F7F7F7 ;
height: 25px ;min-height: 25px ;
width: 25px ;min-width: 25px ; font-size: 25px ;
}
section.postbranch3[custom-agua-item="layer"] {
    

margin-top: 12px ;
box-sizing: border-box ;
background: linear-gradient(rgb(51, 179, 174) 60%, transparent) ;
min-height: unset;
height: calc(100% - calc(12px + 0px));

width: 2px ;min-width: 2px ;
}
section.layer-1133-7fb7621a804b4a92b155b60e32577190[custom-agua-item="layer"] {
    
justify-content: center ;

min-height: unset;
height: 100%;

min-width: unset;
width: 100%;
padding-left: var(--halfMarginSidesContent) ; 
padding-right: var(--halfMarginSidesContent) ; 
}
p.text-491-ebe0c899f3ef473881aa156da57d8f84[custom-agua-item="text"] {
     
font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Roboto', sans-serif ;
font-weight: bold ;

background: #FFFFFF ;

font-size: var(--fontMediumTitleSize) ; 
}
section.layer-1134-81373cfe93294cb5b06833b77c5e617c[custom-agua-item="layer"] {
    
border-color: #222222;
border-radius: 0px ;
min-width: unset;
width: calc(100% - var(--81373cfe93294cb5b06833b77c5e617c_margin-right));

position: relative;
z-index: 4;
--margin-top: var(--halfMarginSidesContent);
margin-top: var(--margin-top) ;
margin-right: var(--81373cfe93294cb5b06833b77c5e617c_margin-right) ; 
min-height: var(--min-81373cfe93294cb5b06833b77c5e617c_valueheight) ; 
height: var(--81373cfe93294cb5b06833b77c5e617c_valueheight); 
}
section.layer-274-a0a01b43082f4a26b3f03b9620f4d6b5[custom-agua-item="layer"] {
    

min-height: unset;
height: 100%;

min-width: unset;
width: 100%;

position: absolute;
}
section.layer-275-1f89ee9830154252acdc424ebd93f708[custom-agua-item="layer"] {
    

margin-top: 2px ;
margin-bottom: 2px ;
margin-left: 2px ;
margin-right: 2px ;
box-sizing: border-box ;
min-height: unset;
height: calc(100% - calc(2px + 2px));

min-width: unset;
width: calc(100% - calc(2px + 2px));

position: relative;
}
.imageview-73-d0f893255aec42c9a1ec48215ae805d6[custom-agua-item="imageView"] {
    
min-height: unset;
height: 100%;

min-width: unset;
width: 100%;

}
section.madeinagua[custom-agua-item="layer"] {
    
align-items: center ;

margin-bottom: 64px ;
box-sizing: border-box ;
min-height:  unset ;height: fit-content ;
min-width: unset;
width: 100%;

position: relative;padding-top: var(--madeinagua_padding-top) ; 
background: var(--madeinagua_backgroundColor) ; 
}
section.stickymadeinagua[custom-agua-item="layer"] {
    flex-direction: row ;
align-items: center ;
justify-content: center ;
border-color: #CE93D8;
padding-top: 35px ;
padding-bottom: 35px ;
padding-left: 70px ;
padding-right: 70px ;
box-sizing: border-box ;
background: linear-gradient(180deg,rgba(0,0,0,.6) 0%,rgba(0,0,0,0.8) 100%) ;
min-height:  unset ;height: fit-content ;
min-width:  unset ;width: fit-content ;
position: sticky;
top: 40vh;margin-top: var(--stickymadeinagua_margin-top) ; 
border-radius: var(--stickymadeinagua_radiusAbsolute) ; 
}
.blurbg {
    backdrop-filter: blur(10px);
}
p.text-21-31b5350532034d90a18e788d121b5772[custom-agua-item="text"] {
     
font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Roboto', sans-serif ;
font-weight: bold ;

background: #FFFFFF ;

font-size: var(--fontTitleSize) ; 
}
p.text-22-8b05a5c1aa37422c88cd8a2ee65ac2d9[custom-agua-item="text"] {
     
font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Roboto', sans-serif ;
font-weight: bold ;

margin-left: 30px ;
box-sizing: border-box ;
background: #FFFFFF ;

font-size: var(--fontTitleSize) ; 
}
section.madeincontent[custom-agua-item="layer"] {
    flex-direction: row ;

background-color: #000000 ;
min-height:  unset ;height: fit-content ;
min-width: unset;
width: 100%;
padding-bottom: var(--madeincontent_padding-bottom) ; 
margin-top: var(--madeincontent_margin-top) ; 
}
section.imagescolumn1[custom-agua-item="layer"] {
    
align-items: center ;

margin-top: 32px ;
box-sizing: border-box ;
min-height:  unset ;height: fit-content ;
min-width: unset;
width: 100%;

}
section.layer-48-df3e79908bc749d198400b8817fe0281[custom-agua-item="layer"] {
    
border: 3px solid #E57373;
border-radius: 12px ;background-repeat: no-repeat ;
background-size: cover ;
background-position: center ;
background-image: url('../../../assets/madein1apple.png') ;min-width: var(--min-df3e79908bc749d198400b8817fe0281_valuewidth) ; 
width: var(--df3e79908bc749d198400b8817fe0281_valuewidth); 
min-height: var(--min-df3e79908bc749d198400b8817fe0281_valueheight) ; 
height: var(--df3e79908bc749d198400b8817fe0281_valueheight); 
}
.growhover {
  top: 0px;
  transition: 0.5s;
}

.growhover:hover {
  margin-top: -20px !important; 
}

section.layer-483-fa0e88e0e4f34f26a1259176b11b6835[custom-agua-item="layer"] {
    
border: 3px solid #4FC3F7;
border-radius: 12px ;
margin-top: 2vw ;
box-sizing: border-box ;background-repeat: no-repeat ;
background-size: cover ;
background-position: center ;
background-image: url('../../../assets/madein1apple.png') ;min-width: var(--min-fa0e88e0e4f34f26a1259176b11b6835_valuewidth) ; 
width: var(--fa0e88e0e4f34f26a1259176b11b6835_valuewidth); 
min-height: var(--min-fa0e88e0e4f34f26a1259176b11b6835_valueheight) ; 
height: var(--fa0e88e0e4f34f26a1259176b11b6835_valueheight); 
}
section.layer-4832-4f3b8726448d4691b089489fe07b2cd4[custom-agua-item="layer"] {
    
border: 3px solid #FFF176;
border-radius: 12px ;
margin-top: 2vw ;
box-sizing: border-box ;background-repeat: no-repeat ;
background-size: cover ;
background-position: center ;
background-image: url('../../../assets/madein1apple.png') ;min-width: var(--min-4f3b8726448d4691b089489fe07b2cd4_valuewidth) ; 
width: var(--4f3b8726448d4691b089489fe07b2cd4_valuewidth); 
min-height: var(--min-4f3b8726448d4691b089489fe07b2cd4_valueheight) ; 
height: var(--4f3b8726448d4691b089489fe07b2cd4_valueheight); 
}
section.layer-4833-bb582d4b4f0f4fe1ae6b313f127de88c[custom-agua-item="layer"] {
    
border: 3px solid #F06292;
border-radius: 12px ;
margin-top: 2vw ;
box-sizing: border-box ;background-repeat: no-repeat ;
background-size: cover ;
background-position: center ;
background-image: url('../../../assets/madein1apple.png') ;min-width: var(--min-bb582d4b4f0f4fe1ae6b313f127de88c_valuewidth) ; 
width: var(--bb582d4b4f0f4fe1ae6b313f127de88c_valuewidth); 
min-height: var(--min-bb582d4b4f0f4fe1ae6b313f127de88c_valueheight) ; 
height: var(--bb582d4b4f0f4fe1ae6b313f127de88c_valueheight); 
}
section.layer-4834-248333696458415c835f3716b0af867e[custom-agua-item="layer"] {
    
border: 3px solid #4DD0E1;
border-radius: 12px ;
margin-top: 2vw ;
box-sizing: border-box ;background-repeat: no-repeat ;
background-size: cover ;
background-position: center ;
background-image: url('../../../assets/madein1apple.png') ;min-width: var(--min-248333696458415c835f3716b0af867e_valuewidth) ; 
width: var(--248333696458415c835f3716b0af867e_valuewidth); 
min-height: var(--min-248333696458415c835f3716b0af867e_valueheight) ; 
height: var(--248333696458415c835f3716b0af867e_valueheight); 
}
section.layer-4835-19f2eac4867a471d8bcf6d9db117fd2b[custom-agua-item="layer"] {
    
border: 3px solid #FFD54F;
border-radius: 12px ;
margin-top: 2vw ;
box-sizing: border-box ;background-repeat: no-repeat ;
background-size: cover ;
background-position: center ;
background-image: url('../../../assets/madein1apple.png') ;min-width: var(--min-19f2eac4867a471d8bcf6d9db117fd2b_valuewidth) ; 
width: var(--19f2eac4867a471d8bcf6d9db117fd2b_valuewidth); 
min-height: var(--min-19f2eac4867a471d8bcf6d9db117fd2b_valueheight) ; 
height: var(--19f2eac4867a471d8bcf6d9db117fd2b_valueheight); 
}
section.imagescolumn2[custom-agua-item="layer"] {
    
align-items: center ;

min-height:  unset ;height: fit-content ;
min-width: unset;
width: 100%;
display: var(--imagescolumn2_visible) ; 
}
section.layer-482-f3ee3d148bfa4c23b0a24992020ace33[custom-agua-item="layer"] {
    
border: 3px solid #FFB74D;
border-radius: 12px ;background-repeat: no-repeat ;
background-size: cover ;
background-position: center ;
background-image: url('../../../assets/madein1apple.png') ;
height: 30vw ;min-height: 30vw ;
width: 30vw ;min-width: 30vw ;
}
section.layer-4836-76c9bfe1c18b44ce8231250d9a1937e2[custom-agua-item="layer"] {
    
border: 3px solid #4DB6AC;
border-radius: 12px ;
margin-top: 2vw ;
box-sizing: border-box ;background-repeat: no-repeat ;
background-size: cover ;
background-position: center ;
background-image: url('../../../assets/madein1apple.png') ;
height: 30vw ;min-height: 30vw ;
width: 30vw ;min-width: 30vw ;
}
section.layer-4837-aaf655d661974817a563a731f59bcbf1[custom-agua-item="layer"] {
    
border: 3px solid #BA68C8;
border-radius: 12px ;
margin-top: 2vw ;
box-sizing: border-box ;background-repeat: no-repeat ;
background-size: cover ;
background-position: center ;
background-image: url('../../../assets/madein1apple.png') ;
height: 30vw ;min-height: 30vw ;
width: 30vw ;min-width: 30vw ;
}
section.layer-4838-65c39d2c35ee44f38e4ef05f51d0256e[custom-agua-item="layer"] {
    
border: 3px solid #AED581;
border-radius: 12px ;
margin-top: 2vw ;
box-sizing: border-box ;background-repeat: no-repeat ;
background-size: cover ;
background-position: center ;
background-image: url('../../../assets/madein1apple.png') ;
height: 30vw ;min-height: 30vw ;
width: 30vw ;min-width: 30vw ;
}
section.layer-4839-aa2b97215490413f87fbf995c0ea252e[custom-agua-item="layer"] {
    
border: 3px solid #FF8A65;
border-radius: 12px ;
margin-top: 2vw ;
box-sizing: border-box ;background-repeat: no-repeat ;
background-size: cover ;
background-position: center ;
background-image: url('../../../assets/madein1apple.png') ;
height: 30vw ;min-height: 30vw ;
width: 30vw ;min-width: 30vw ;
}
section.layer-48310-97bd0c6ef220447e8ff9a8e24e219b00[custom-agua-item="layer"] {
    
border: 3px solid #A1887F;
border-radius: 12px ;
margin-top: 2vw ;
box-sizing: border-box ;background-repeat: no-repeat ;
background-size: cover ;
background-position: center ;
background-image: url('../../../assets/madein1apple.png') ;
height: 30vw ;min-height: 30vw ;
width: 30vw ;min-width: 30vw ;
}
section.imagescolumn3[custom-agua-item="layer"] {
    
align-items: center ;

margin-top: 32px ;
box-sizing: border-box ;
min-height:  unset ;height: fit-content ;
min-width: unset;
width: 100%;

}
section.layer-4822-6681986d05ac40dca996bc4cb39b0513[custom-agua-item="layer"] {
    
border: 3px solid #64B5F6;
border-radius: 12px ;background-repeat: no-repeat ;
background-size: cover ;
background-position: center ;
background-image: url('../../../assets/madein1apple.png') ;min-width: var(--min-6681986d05ac40dca996bc4cb39b0513_valuewidth) ; 
width: var(--6681986d05ac40dca996bc4cb39b0513_valuewidth); 
min-height: var(--min-6681986d05ac40dca996bc4cb39b0513_valueheight) ; 
height: var(--6681986d05ac40dca996bc4cb39b0513_valueheight); 
}
section.layer-48311-b119136414454c9a876b8e609f19311c[custom-agua-item="layer"] {
    
border: 3px solid #AED581;
border-radius: 12px ;
margin-top: 2vw ;
box-sizing: border-box ;background-repeat: no-repeat ;
background-size: cover ;
background-position: center ;
background-image: url('../../../assets/madein1apple.png') ;min-width: var(--min-b119136414454c9a876b8e609f19311c_valuewidth) ; 
width: var(--b119136414454c9a876b8e609f19311c_valuewidth); 
min-height: var(--min-b119136414454c9a876b8e609f19311c_valueheight) ; 
height: var(--b119136414454c9a876b8e609f19311c_valueheight); 
}
section.layer-48312-353d66af51214b3da0e916f7d4267dd1[custom-agua-item="layer"] {
    
border: 3px solid #FF8A65;
border-radius: 12px ;
margin-top: 2vw ;
box-sizing: border-box ;background-repeat: no-repeat ;
background-size: cover ;
background-position: center ;
background-image: url('../../../assets/madein1apple.png') ;min-height: var(--min-353d66af51214b3da0e916f7d4267dd1_valueheight) ; 
height: var(--353d66af51214b3da0e916f7d4267dd1_valueheight); 
min-width: var(--min-353d66af51214b3da0e916f7d4267dd1_valuewidth) ; 
width: var(--353d66af51214b3da0e916f7d4267dd1_valuewidth); 
}
section.layer-48313-76784e55ba824ed69dd31176c073f383[custom-agua-item="layer"] {
    
border: 3px solid #E57373;
border-radius: 12px ;
margin-top: 2vw ;
box-sizing: border-box ;background-repeat: no-repeat ;
background-size: cover ;
background-position: center ;
background-image: url('../../../assets/madein1apple.png') ;min-height: var(--min-76784e55ba824ed69dd31176c073f383_valueheight) ; 
height: var(--76784e55ba824ed69dd31176c073f383_valueheight); 
min-width: var(--min-76784e55ba824ed69dd31176c073f383_valuewidth) ; 
width: var(--76784e55ba824ed69dd31176c073f383_valuewidth); 
}
section.layer-48314-b5afd3d872764e22b7b09e9c1d4244d6[custom-agua-item="layer"] {
    
border: 3px solid #4FC3F7;
border-radius: 12px ;
margin-top: 2vw ;
box-sizing: border-box ;background-repeat: no-repeat ;
background-size: cover ;
background-position: center ;
background-image: url('../../../assets/madein1apple.png') ;min-height: var(--min-b5afd3d872764e22b7b09e9c1d4244d6_valueheight) ; 
height: var(--b5afd3d872764e22b7b09e9c1d4244d6_valueheight); 
min-width: var(--min-b5afd3d872764e22b7b09e9c1d4244d6_valuewidth) ; 
width: var(--b5afd3d872764e22b7b09e9c1d4244d6_valuewidth); 
}
section.layer-48315-79360d610be641a089d3a99b9ec83acc[custom-agua-item="layer"] {
    
border: 3px solid #F06292;
border-radius: 12px ;
margin-top: 2vw ;
box-sizing: border-box ;background-repeat: no-repeat ;
background-size: cover ;
background-position: center ;
background-image: url('../../../assets/madein1apple.png') ;min-height: var(--min-79360d610be641a089d3a99b9ec83acc_valueheight) ; 
height: var(--79360d610be641a089d3a99b9ec83acc_valueheight); 
min-width: var(--min-79360d610be641a089d3a99b9ec83acc_valuewidth) ; 
width: var(--79360d610be641a089d3a99b9ec83acc_valuewidth); 
}
section.usersloveus[custom-agua-item="layer"] {
    

background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(6,5,12,1) 80%); ;
min-width: unset;
width: 100%;

position: relative;min-height: var(--min-usersloveus_valueheight) ; 
height: var(--usersloveus_valueheight); 
}
section.fadetopurple[custom-agua-item="layer"] {
    

min-height: unset;
height: 100%;

min-width: unset;
width: 100%;

}
section.sponsorscard[custom-agua-item="layer"] {
    
border-color: #222222;
border-radius: 20px ;
min-width: unset;
width: calc(100% - calc(var(--marginSidesContent) + var(--marginSidesContent)));

position: absolute;
z-index: 0;margin-left: var(--marginSidesContent) ; 
margin-right: var(--marginSidesContent) ; 
min-height: var(--min-sponsorscard_valueheight) ; 
height: var(--sponsorscard_valueheight); 
}
section.glowsponsors[custom-agua-item="layer"] {
    

min-height: unset;
height: 100%;

min-width: unset;
width: 100%;

position: absolute;
z-index: 0;
}
section.layer-658-1f741291c7d04ad498c3accc917ea008[custom-agua-item="layer"] {
    
min-width: unset;
width: 100%;

position: absolute;flex-direction: var(--1f741291c7d04ad498c3accc917ea008_orientation) ; 
min-height: var(--min-1f741291c7d04ad498c3accc917ea008_valueheight) ; 
height: var(--1f741291c7d04ad498c3accc917ea008_valueheight); 
--verticalAxis-1f741291c7d04ad498c3accc917ea008: var(--1f741291c7d04ad498c3accc917ea008_verticalAlignment); 
--horizontalAxis-1f741291c7d04ad498c3accc917ea008: var(--1f741291c7d04ad498c3accc917ea008_horizontalAlignment); 
border-radius: var(--1f741291c7d04ad498c3accc917ea008_radiusAbsolute) ; 
}
.overflowhidden {
    overflow: hidden;
}
.imageview-82-aaff49445ae645d48ccd1ad42f638146[custom-agua-item="imageView"] {
    display: var(--aaff49445ae645d48ccd1ad42f638146_visible) ; 
min-width: var(--min-aaff49445ae645d48ccd1ad42f638146_valuewidth) ; 
width: var(--aaff49445ae645d48ccd1ad42f638146_valuewidth); 
min-height: var(--min-aaff49445ae645d48ccd1ad42f638146_valueheight) ; 
height: var(--aaff49445ae645d48ccd1ad42f638146_valueheight); 
}
section.layer-659-5424eaba04cd43a391bc3db1ec3e85ee[custom-agua-item="layer"] {
    
justify-content: space-between ;

min-height: unset;
height: 100%;

min-width: unset;
width: 100%;
padding-left: var(--marginSidesContent) ; 
padding-top: var(--marginSidesContent) ; 
padding-bottom: var(--marginSidesContent) ; 
}
section.sponsorstext[custom-agua-item="layer"] {
    

height: 100px ;min-height: 100px ;min-width: var(--min-sponsorstext_valuewidth) ; 
width: var(--sponsorstext_valuewidth); 
margin-top: var(--sponsorstext_margin-top) ; 

--margin-left: var(--sponsorstext_margin-left);
margin-left: var(--margin-left) ;
}
section.layer-501-09ce6dbefef44baab608e4523505f087[custom-agua-item="layer"] {
    flex-direction: row ;

min-height:  unset ;height: fit-content ;
min-width: unset;
width: 100%;

}
p.text-201-3da03a479a9c466492a5166a225a5ce5[custom-agua-item="text"] {
     
font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Roboto', sans-serif ;
font-weight: bold ;

background: #FFFFFF ;

font-size: var(--fontMediumTitleSize) ; 
}
p.text-202-d5eab28e187341e09898d287c8e4a367[custom-agua-item="text"] {
     
font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Roboto', sans-serif ;


margin-left: 10px ;
box-sizing: border-box ;
background: #FFFFFF ;

font-size: var(--fontMediumTitleSize) ; 
}
p.text-203-6119b965fd33494b9b3d0272be2bd38b[custom-agua-item="text"] {
     
font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Roboto', sans-serif ;


background: #FFFFFF ;

font-size: var(--fontMediumTitleSize) ; 
}
p.text-204-33bde1096256421796484568579acf50[custom-agua-item="text"] {
     
font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Roboto', sans-serif ;


background: #FFFFFF ;

font-size: var(--fontMediumTitleSize) ; 
}
section.sponsorscta[custom-agua-item="layer"] {
    flex-direction: row ;
cursor: pointer;
align-items: center ;
justify-content: center ;

min-height:  unset ;height: fit-content ;
min-width:  unset ;width: fit-content ;margin-left: var(--sponsorscta_margin-left) ; 
margin-bottom: var(--sponsorscta_margin-bottom) ; 
}
p.text-205-4a9b9ad36cea4b10a4764c0d57dcd136[custom-agua-item="text"] {
     
font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Roboto', sans-serif ;

font-weight: bold ;

background: #FFFFFF ;


}
.icon-21-1855a799d0bb4ac594f9848d1eea58d2[custom-agua-item="icon"] {
    
margin-left: 10px ;
box-sizing: border-box ;
background: #FEFEFE ;
height: 20px ;min-height: 20px ;
width: 20px ;min-width: 20px ; font-size: 20px ;
}
.imageview-57-d9cd3baba0f44deaa8fa608dd8a896df[custom-agua-item="imageView"] {
    min-width: var(--min-d9cd3baba0f44deaa8fa608dd8a896df_valuewidth) ; 
width: var(--d9cd3baba0f44deaa8fa608dd8a896df_valuewidth); 
min-height: var(--min-d9cd3baba0f44deaa8fa608dd8a896df_valueheight) ; 
height: var(--d9cd3baba0f44deaa8fa608dd8a896df_valueheight); 
display: var(--d9cd3baba0f44deaa8fa608dd8a896df_visible) ; 
}
section.community1[custom-agua-item="layer"] {
    flex-direction: row ;

min-height:  unset ;height: fit-content ;
min-width: unset;
width: calc(100% - var(--community1_margin-left));

position: relative;padding-left: var(--community1_padding-left) ; 
margin-left: var(--community1_margin-left) ; 
padding-right: var(--community1_padding-right) ; 
padding-top: var(--community1_padding-top) ; 
align-items: var(--community1_verticalAlignment) ; 
}
section.layer-132-0e2a596e690148589d15af21e417d9db[custom-agua-item="layer"] {
    

margin-top: 64px ;
box-sizing: border-box ;
min-width: unset;
width: 100%;

position: absolute;
z-index: 0;min-height: var(--min-0e2a596e690148589d15af21e417d9db_valueheight) ; 
height: var(--0e2a596e690148589d15af21e417d9db_valueheight); 
}
.negativemarginleft {
    transform: translateX(25%);
}
section.fadecoverblack[custom-agua-item="layer"] {
    

background: linear-gradient(180deg, rgba(3,0,19,0) 0%, rgba(0,0,0,1) 85%) ;
min-height: unset;
height: 100%;

min-width: unset;
width: 100%;

position: absolute;
z-index: 2;
}
.globeimg[custom-agua-item="imageView"] {
    
min-height: unset;
height: 100%;

min-width: unset;
width: 100%;

}
section.layer-765-33e2fd2d38f3456198999635258f79d0[custom-agua-item="layer"] {
    
justify-content: center ;
align-items: center ;

min-height:  unset ;height: fit-content ;
min-width:  unset ;width: fit-content ;
position: relative;
}
section.branch10[custom-agua-item="layer"] {
    

margin-bottom: 12px ;
box-sizing: border-box ;
background: linear-gradient(transparent, rgb(102, 57, 186) 10%, rgb(163, 113, 247), rgb(210, 168, 255), rgb(102, 57, 186) 90%, transparent) ;
height: 350px ;min-height: 350px ;
width: 2px ;min-width: 2px ;
}
section.iconglow10[custom-agua-item="layer"] {
    

min-height:  unset ;height: fit-content ;
min-width:  unset ;width: fit-content ;
position: relative;
}
section.layer-496-c6c2281596674780a4dcd0646d7402ec[custom-agua-item="layer"] {
    

background-color: #8C4EFF ;
height: 30px ;min-height: 30px ;
width: 30px ;min-width: 30px ;
position: absolute;
z-index: 0;
}
section.layer-495-582fc7a023b44ed39e89ca37f72b9dba[custom-agua-item="layer"] {
    

min-height:  unset ;height: fit-content ;
min-width:  unset ;width: fit-content ;
position: relative;
z-index: 0;
}
.icon-36-d8966b7596ed4f31a34a3fc33cd436cb[custom-agua-item="icon"] {
    
background: #FEFEFE ;
height: 25px ;min-height: 25px ;
width: 25px ;min-width: 25px ; font-size: 25px ;
}
section.branch10bottom[custom-agua-item="layer"] {
    

margin-top: 12px ;
box-sizing: border-box ;
background: linear-gradient(transparent, rgb(102, 57, 186) 10%, rgb(163, 113, 247), rgb(210, 168, 255), rgb(102, 57, 186) 90%, transparent) ;
height: 350px ;min-height: 350px ;
width: 2px ;min-width: 2px ;
}
section.layer-233-90e50c2813b742208e59a1a07c3b4602[custom-agua-item="layer"] {
    

position: relative;
--margin-left: var(--90e50c2813b742208e59a1a07c3b4602_margin-left);
margin-left: var(--margin-left) ;
min-width: var(--min-90e50c2813b742208e59a1a07c3b4602_valuewidth) ; 
width: var(--90e50c2813b742208e59a1a07c3b4602_valuewidth); 
min-height: var(--min-90e50c2813b742208e59a1a07c3b4602_valueheight) ; 
height: var(--90e50c2813b742208e59a1a07c3b4602_valueheight); 
justify-content: var(--90e50c2813b742208e59a1a07c3b4602_verticalAlignment) ; 
}
p.text-61-9c9a5ec6a8d445b39b81e2b49d8e0083[custom-agua-item="text"] {
     
font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Roboto', sans-serif ;
font-weight: bold ;

background: #FFFFFF ;
font-size: var(--fontTitleLarge) ; 
min-width: var(--min-9c9a5ec6a8d445b39b81e2b49d8e0083_valuewidth) ; 
width: var(--9c9a5ec6a8d445b39b81e2b49d8e0083_valuewidth); 
}
section.layer-234-e07bff9acd7045529800eed43e0a41a1[custom-agua-item="layer"] {
    flex-direction: row ;

min-height:  unset ;height: fit-content ;
min-width:  unset ;width: fit-content ;
}
p.text-116-30cc34c621f54beea420476bf64039d2[custom-agua-item="text"] {
     
font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Roboto', sans-serif ;
font-weight: bold ;

background: #ffffff ;

font-size: var(--fontTitleLarge) ; 
}
p.text-115-7f93866f75824253b195dda2be5559d8[custom-agua-item="text"] {
     
font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Roboto', sans-serif ;
font-weight: bold ;

margin-left: 15px ;
box-sizing: border-box ;
background: linear-gradient(50deg,#714dff,#9c83ff 31.28%,#e151ff 77.97%,#fff759 95.64%) ;

font-size: var(--fontTitleLarge) ; 
}
p.text-117-735fd6031d874acfbe6f6a0b55f534e2[custom-agua-item="text"] {
     
font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Roboto', sans-serif ;



margin-top: 32px ;
margin-bottom: 32px ;
box-sizing: border-box ;
background: #A6A6A6 ;
min-width: var(--min-735fd6031d874acfbe6f6a0b55f534e2_valuewidth) ; 
width: var(--735fd6031d874acfbe6f6a0b55f534e2_valuewidth); 
}
section.joinaguacommunity[custom-agua-item="layer"] {
    flex-direction: row ;
cursor: pointer;
align-items: center ;
justify-content: center ;
border: 1px solid #BA68C8;
border-radius: 6px ;
padding-top: 8px ;
padding-bottom: 8px ;
padding-left: 16px ;
padding-right: 16px ;
box-sizing: border-box ;
min-height:  unset ;height: fit-content ;
min-width:  unset ;width: fit-content ;
z-index: 1;
}
p.text-63-a84d41f5f167455f957cb5713cebd2f3[custom-agua-item="text"] {
     
font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Roboto', sans-serif ;



background: #FFFFFF ;


}
.icon-6-bf913b1e6e564cc182619ea6a145db7c[custom-agua-item="icon"] {
    
margin-left: 9px ;
box-sizing: border-box ;
background: #FEFEFE ;
height: 20px ;min-height: 20px ;
width: 20px ;min-width: 20px ; font-size: 20px ;
}
section.footercontainer[custom-agua-item="layer"] {
    

padding-top: 32px ;
padding-bottom: 32px ;
box-sizing: border-box ;
background-color: #000000 ;
min-height:  unset ;height: fit-content ;
min-width: unset;
width: 100%;
padding-left: var(--footercontainer_padding-left) ; 
padding-right: var(--footercontainer_padding-right) ; 
}
section.layer-388-2c595456fde34401a2dae65c2ec152be[custom-agua-item="layer"] {
    

min-height:  unset ;height: fit-content ;
min-width: unset;
width: 100%;

}
p.text-189-649dd2e8035f47c3918150dd6dab16ae[custom-agua-item="text"] {
     
font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Roboto', sans-serif ;
font-weight: bold ;

background: #FFFFFF ;

font-size: var(--fontMediumTitleSize) ; 
}
p.text-190-ae53e63bc09245c4a84d2a3e490a8f1e[custom-agua-item="text"] {
     
font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Roboto', sans-serif ;


margin-top: 16px ;
box-sizing: border-box ;
background: #FFFFFF ;

font-size: var(--fontSizeRegular) ; 
}
p.text-191-c66e1d4a9ab747a1a5c01daf40593425[custom-agua-item="text"] {
     
font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Roboto', sans-serif ;


background: #959595 ;

font-size: var(--fontSizeRegular) ; 
}
section.joinfooter[custom-agua-item="layer"] {
    flex-direction: row ;
cursor: pointer;
align-items: center ;
justify-content: center ;
border: 1px solid #BA68C8;
border-radius: 6px ;
padding-top: 8px ;
padding-bottom: 8px ;
padding-left: 16px ;
padding-right: 16px ;
margin-top: 16px ;
box-sizing: border-box ;
min-height:  unset ;height: fit-content ;
min-width:  unset ;width: fit-content ;
}
p.text-192-f4fb291ab1224c98840c7faa59aa353f[custom-agua-item="text"] {
     
font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Roboto', sans-serif ;



background: #FFFFFF ;


}
.icon-16-341c3725c0d84aa39bbeafb1c4ca3324[custom-agua-item="icon"] {
    
margin-left: 10px ;
box-sizing: border-box ;
background: #FEFEFE ;
height: 20px ;min-height: 20px ;
width: 20px ;min-width: 20px ; font-size: 20px ;
}
section.footerseparator[custom-agua-item="layer"] {
    

margin-top: 32px ;
margin-bottom: 32px ;
box-sizing: border-box ;
background-color: #222222 ;
height: 1px ;min-height: 1px ;
min-width: unset;
width: 100%;

}
section.layer-391-e6c2cabe23a74081bcf3b0ff6fd351af[custom-agua-item="layer"] {
    

min-height:  unset ;height: fit-content ;
min-width:  unset ;width: fit-content ;
}
p.text-188-d3fc8e03cbbf45c087682e181fffb92b[custom-agua-item="text"] {
     
font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Roboto', sans-serif ;


background: #FFFFFF ;

font-size: var(--fontSmallTitleSize) ; 
}


section.ctafooter-orientation[custom-agua-item="layer"] {
align-items: var(--verticalAxis-ctafooter) ;
justify-content: var(--horizontalAxis-ctafooter) ;
}
section.featurecards1-orientation[custom-agua-item="layer"] {
align-items: var(--verticalAxis-featurecards1) ;
justify-content: var(--horizontalAxis-featurecards1) ;
}
section.twocolumnscards-orientation[custom-agua-item="layer"] {
align-items: var(--verticalAxis-twocolumnscards) ;
justify-content: var(--horizontalAxis-twocolumnscards) ;
}
section.featurecards5-orientation[custom-agua-item="layer"] {
align-items: var(--verticalAxis-featurecards5) ;
justify-content: var(--horizontalAxis-featurecards5) ;
}
section.layer-658-1f741291c7d04ad498c3accc917ea008-orientation[custom-agua-item="layer"] {
align-items: var(--verticalAxis-1f741291c7d04ad498c3accc917ea008) ;
justify-content: var(--horizontalAxis-1f741291c7d04ad498c3accc917ea008) ;
}
@media (max-width: 480px) {main.Screen[custom-agua-item="screen"] {
	--ctafooter_orientation: column;
	--min-joincommunity_margin-left: 0px;
	--joincommunity_margin-left: 0px;
	--min-joincommunity_margin-top: 15px;
	--joincommunity_margin-top: 15px;
	--min-herobgimage_radiusAbsolute: 5px;
	--herobgimage_radiusAbsolute: 5px;
	--min-spacertop_margin-top: 40vh;
	--spacertop_margin-top: 40vh;
	--imagescolumn2_visible: none;
	--min-6681986d05ac40dca996bc4cb39b0513_valuewidth: 44vw;
	--6681986d05ac40dca996bc4cb39b0513_valuewidth: 44vw;
	--min-6681986d05ac40dca996bc4cb39b0513_valueheight: 44vw;
	--6681986d05ac40dca996bc4cb39b0513_valueheight: 44vw;
	--min-df3e79908bc749d198400b8817fe0281_valuewidth: 44vw;
	--df3e79908bc749d198400b8817fe0281_valuewidth: 44vw;
	--min-df3e79908bc749d198400b8817fe0281_valueheight: 44vw;
	--df3e79908bc749d198400b8817fe0281_valueheight: 44vw;
	--min-fa0e88e0e4f34f26a1259176b11b6835_valuewidth: 44vw;
	--fa0e88e0e4f34f26a1259176b11b6835_valuewidth: 44vw;
	--min-fa0e88e0e4f34f26a1259176b11b6835_valueheight: 44vw;
	--fa0e88e0e4f34f26a1259176b11b6835_valueheight: 44vw;
	--min-4f3b8726448d4691b089489fe07b2cd4_valuewidth: 44vw;
	--4f3b8726448d4691b089489fe07b2cd4_valuewidth: 44vw;
	--min-4f3b8726448d4691b089489fe07b2cd4_valueheight: 44vw;
	--4f3b8726448d4691b089489fe07b2cd4_valueheight: 44vw;
	--min-bb582d4b4f0f4fe1ae6b313f127de88c_valuewidth: 44vw;
	--bb582d4b4f0f4fe1ae6b313f127de88c_valuewidth: 44vw;
	--min-bb582d4b4f0f4fe1ae6b313f127de88c_valueheight: 44vw;
	--bb582d4b4f0f4fe1ae6b313f127de88c_valueheight: 44vw;
	--min-248333696458415c835f3716b0af867e_valuewidth: 44vw;
	--248333696458415c835f3716b0af867e_valuewidth: 44vw;
	--min-248333696458415c835f3716b0af867e_valueheight: 44vw;
	--248333696458415c835f3716b0af867e_valueheight: 44vw;
	--min-19f2eac4867a471d8bcf6d9db117fd2b_valuewidth: 44vw;
	--19f2eac4867a471d8bcf6d9db117fd2b_valuewidth: 44vw;
	--min-19f2eac4867a471d8bcf6d9db117fd2b_valueheight: 44vw;
	--19f2eac4867a471d8bcf6d9db117fd2b_valueheight: 44vw;
	--min-b119136414454c9a876b8e609f19311c_valuewidth: 44vw;
	--b119136414454c9a876b8e609f19311c_valuewidth: 44vw;
	--min-b119136414454c9a876b8e609f19311c_valueheight: 44vw;
	--b119136414454c9a876b8e609f19311c_valueheight: 44vw;
	--min-353d66af51214b3da0e916f7d4267dd1_valueheight: 44vw;
	--353d66af51214b3da0e916f7d4267dd1_valueheight: 44vw;
	--min-353d66af51214b3da0e916f7d4267dd1_valuewidth: 44vw;
	--353d66af51214b3da0e916f7d4267dd1_valuewidth: 44vw;
	--min-76784e55ba824ed69dd31176c073f383_valueheight: 44vw;
	--76784e55ba824ed69dd31176c073f383_valueheight: 44vw;
	--min-76784e55ba824ed69dd31176c073f383_valuewidth: 44vw;
	--76784e55ba824ed69dd31176c073f383_valuewidth: 44vw;
	--min-b5afd3d872764e22b7b09e9c1d4244d6_valueheight: 44vw;
	--b5afd3d872764e22b7b09e9c1d4244d6_valueheight: 44vw;
	--min-b5afd3d872764e22b7b09e9c1d4244d6_valuewidth: 44vw;
	--b5afd3d872764e22b7b09e9c1d4244d6_valuewidth: 44vw;
	--min-79360d610be641a089d3a99b9ec83acc_valueheight: 44vw;
	--79360d610be641a089d3a99b9ec83acc_valueheight: 44vw;
	--min-79360d610be641a089d3a99b9ec83acc_valuewidth: 44vw;
	--79360d610be641a089d3a99b9ec83acc_valuewidth: 44vw;
	--min-bgfooter_valuewidth: 100%;
	--bgfooter_valuewidth: 100%;
	--min-madeincontent_padding-bottom: 150px;
	--madeincontent_padding-bottom: 150px;
	--min-madeincontent_margin-top: 0px;
	--madeincontent_margin-top: 0px;
	--min-stickymadeinagua_margin-top: 0px;
	--stickymadeinagua_margin-top: 0px;
	--min-madeinagua_padding-top: 0px;
	--madeinagua_padding-top: 0px;
	--min-imagefeaturescontainer_valueheight: 56vw;
	--imagefeaturescontainer_valueheight: 56vw;
	--min-imagefeaturescontainer_valuewidth: 100vw;
	--imagefeaturescontainer_valuewidth: 100vw;
	--min-featurescontent_margin-top: 100px;
	--featurescontent_margin-top: 100px;
	--min-stickymadeinagua_radiusAbsolute: 30px;
	--stickymadeinagua_radiusAbsolute: 30px;
	--featurecards1_orientation: column;
	--97f49bb246714c2f9a5d623b7d98c3e1_horizontalAlignment: center ;;
	--min-10b1968fafc24af69b91d41fcb7dbf7c_margin-left: 12px;
	--10b1968fafc24af69b91d41fcb7dbf7c_margin-left: 12px;
	--min-10b1968fafc24af69b91d41fcb7dbf7c_margin-right: 12px;
	--10b1968fafc24af69b91d41fcb7dbf7c_margin-right: 12px;
	--min-fa709c781c564ad7a917fd694e87fa0c_margin-left: 12px;
	--fa709c781c564ad7a917fd694e87fa0c_margin-left: 12px;
	--min-fa709c781c564ad7a917fd694e87fa0c_margin-right: 12px;
	--fa709c781c564ad7a917fd694e87fa0c_margin-right: 12px;
	--min-1a0531d15e4748e0a10c485b417a4def_valuewidth: unset;
	--1a0531d15e4748e0a10c485b417a4def_valuewidth: 100%;
	--min-card3_margin-left: 12px;
	--card3_margin-left: 12px;
	--min-card3_margin-right: 12px;
	--card3_margin-right: 12px;
	--min-featurecards2_padding-top: 0px;
	--featurecards2_padding-top: 0px;
	--min-1a0531d15e4748e0a10c485b417a4def_valueheight: unset;
	--1a0531d15e4748e0a10c485b417a4def_valueheight: 100%;
	--min-1f493a2b0a644c77bd2c2825a68c29e4_margin-left: 6px;
	--1f493a2b0a644c77bd2c2825a68c29e4_margin-left: 6px;
	--min-1a0531d15e4748e0a10c485b417a4def_margin-left: 0px;
	--1a0531d15e4748e0a10c485b417a4def_margin-left: 0px;
	--min-1a0531d15e4748e0a10c485b417a4def_margin-right: 0px;
	--1a0531d15e4748e0a10c485b417a4def_margin-right: 0px;
	--min-line1card3_margin-top: 20px;
	--line1card3_margin-top: 20px;
	--min-container_padding-bottom: 0px;
	--container_padding-bottom: 0px;
	--min-da6a28df823a420eb39081269d975369_valuewidth: unset;
	--da6a28df823a420eb39081269d975369_valuewidth: 100%;
	--min-5b547c317a9c45848f2c97a9a08cadc8_valueheight: 400px;
	--5b547c317a9c45848f2c97a9a08cadc8_valueheight: 400px;
	--min-5b547c317a9c45848f2c97a9a08cadc8_valuewidth: unset;
	--5b547c317a9c45848f2c97a9a08cadc8_valuewidth: 100%;
	--min-footercontainer_padding-left: 32px;
	--footercontainer_padding-left: 32px;
	--min-footercontainer_padding-right: 32px;
	--footercontainer_padding-right: 32px;
	--min-community1_padding-left: 0px;
	--community1_padding-left: 0px;
	--min-community1_margin-left: 32px;
	--community1_margin-left: 32px;
	--min-9c9a5ec6a8d445b39b81e2b49d8e0083_valuewidth: unset;
	--9c9a5ec6a8d445b39b81e2b49d8e0083_valuewidth: 100%;
	--min-90e50c2813b742208e59a1a07c3b4602_margin-left: 16px;
	--90e50c2813b742208e59a1a07c3b4602_margin-left: 16px;
	--min-735fd6031d874acfbe6f6a0b55f534e2_valuewidth: unset;
	--735fd6031d874acfbe6f6a0b55f534e2_valuewidth: 100%;
	--min-90e50c2813b742208e59a1a07c3b4602_valuewidth: unset;
	--90e50c2813b742208e59a1a07c3b4602_valuewidth: 100%;
	--min-community1_padding-right: 16px;
	--community1_padding-right: 16px;
	--min-community1_padding-top: 0px;
	--community1_padding-top: 0px;
	--min-usersloveus_valueheight: 60vh;
	--usersloveus_valueheight: 60vh;
	--madeinagua_backgroundColor: #000000;
	--433545bdefdd42d991a3af6803271662_visible: flex;
	--ea5a4579043e45f5969fc36cbb468631_visible: flex;
	--min-card4_valuewidth: unset;
	--card4_valuewidth: 100%;
	--card3container_wrap: wrap;
	--min-2f43cbedaf8e4f5481fd0deb94739f6b_valueheight: unset;
	--2f43cbedaf8e4f5481fd0deb94739f6b_valueheight: 100%;
	--min-2f43cbedaf8e4f5481fd0deb94739f6b_valuewidth: unset;
	--2f43cbedaf8e4f5481fd0deb94739f6b_valuewidth: 100%;
	--2f43cbedaf8e4f5481fd0deb94739f6b_visible: flex;
	--min-card2menu_margin-top: 12px;
	--card2menu_margin-top: 12px;
	--min-card2menu_margin-bottom: 12px;
	--card2menu_margin-bottom: 12px;
	--card1menu_horizontalAlignment: flex-start ;;
	--card1menu_verticalAlignment: flex-start ;;
	--card2menu_horizontalAlignment: flex-start ;;
	--card2menu_verticalAlignment: flex-start ;;
	--4955e12c96ec4639966207fa2aa6f555_horizontalAlignment: flex-start ;;
	--4955e12c96ec4639966207fa2aa6f555_verticalAlignment: flex-start ;;
	--1f741291c7d04ad498c3accc917ea008_orientation: column;
	--min-d9cd3baba0f44deaa8fa608dd8a896df_valuewidth: 808px;
	--d9cd3baba0f44deaa8fa608dd8a896df_valuewidth: 808px;
	--min-d9cd3baba0f44deaa8fa608dd8a896df_valueheight: 600px;
	--d9cd3baba0f44deaa8fa608dd8a896df_valueheight: 600px;
	--d9cd3baba0f44deaa8fa608dd8a896df_visible: none;
	--aaff49445ae645d48ccd1ad42f638146_visible: flex;
	--min-aaff49445ae645d48ccd1ad42f638146_valuewidth: unset;
	--aaff49445ae645d48ccd1ad42f638146_valuewidth: 100%;
	--min-aaff49445ae645d48ccd1ad42f638146_valueheight: unset;
	--aaff49445ae645d48ccd1ad42f638146_valueheight: 100%;
	--min-sponsorstext_valuewidth: 300px;
	--sponsorstext_valuewidth: 300px;
	--min-sponsorstext_margin-top: 32px;
	--sponsorstext_margin-top: 32px;
	--min-1f741291c7d04ad498c3accc917ea008_valueheight: unset;
	--1f741291c7d04ad498c3accc917ea008_valueheight: fit-content;
	--min-sponsorscard_valueheight: 470px;
	--sponsorscard_valueheight: 470px;
	--min-sponsorstext_margin-left: 12px;
	--sponsorstext_margin-left: 12px;
	--min-sponsorscta_margin-left: 12px;
	--sponsorscta_margin-left: 12px;
	--1f741291c7d04ad498c3accc917ea008_verticalAlignment: flex-start ;;
	--1f741291c7d04ad498c3accc917ea008_horizontalAlignment: space-between ;;
	--min-sponsorscta_margin-bottom: 0px;
	--sponsorscta_margin-bottom: 0px;
	--min-1f741291c7d04ad498c3accc917ea008_radiusAbsolute: 20px;
	--1f741291c7d04ad498c3accc917ea008_radiusAbsolute: 20px;
	--8cfaebf5ef6e43ab9b35060b25738113_text: We are launching our beta!;
	--min-78f69d1d417440e2b88db0edd6cc2325_valuewidth: 30px;
	--78f69d1d417440e2b88db0edd6cc2325_valuewidth: 30px;
	--min-78f69d1d417440e2b88db0edd6cc2325_valueheight: 30px;
	--78f69d1d417440e2b88db0edd6cc2325_valueheight: 30px;
	--min-53ded678cce340be8409af0eda3f5480_padding-left: 24px;
	--53ded678cce340be8409af0eda3f5480_padding-left: 24px;
	--min-53ded678cce340be8409af0eda3f5480_padding-right: 32px;
	--53ded678cce340be8409af0eda3f5480_padding-right: 32px;
	--min-featurecards1_padding-left: 12px;
	--featurecards1_padding-left: 12px;
	--min-card1menu_margin-left: 0px;
	--card1menu_margin-left: 0px;
	--min-card2menu_margin-left: 0px;
	--card2menu_margin-left: 0px;
	--min-card3menu1_margin-left: 0px;
	--card3menu1_margin-left: 0px;
	--min-horizontalcard1_padding-top: 12px;
	--horizontalcard1_padding-top: 12px;
	--min-twocolumnscards_padding-right: 0px;
	--twocolumnscards_padding-right: 0px;
	--min-twocolumnscards_padding-top: 12px;
	--twocolumnscards_padding-top: 12px;
	--min-card8_margin-right: 12px;
	--card8_margin-right: 12px;
	--min-card7_margin-right: 12px;
	--card7_margin-right: 12px;
	--twocolumnscards_orientation: column;
	--min-card8_margin-top: 12px;
	--card8_margin-top: 12px;
	--min-featurecards1_margin-top: 12px;
	--featurecards1_margin-top: 12px;
	--card1menu_backgroundColor: transparent;
	--ea5a4579043e45f5969fc36cbb468631_backgroundColor: transparent;
	--featurecards5_orientation: column;
	--min-81373cfe93294cb5b06833b77c5e617c_margin-right: 0px;
	--81373cfe93294cb5b06833b77c5e617c_margin-right: 0px;
	--min-featurecards5_padding-right: 12px;
	--featurecards5_padding-right: 12px;
	--min-81373cfe93294cb5b06833b77c5e617c_valueheight: 600px;
	--81373cfe93294cb5b06833b77c5e617c_valueheight: 600px;
	--min-0e2a596e690148589d15af21e417d9db_valueheight: 30vh;
	--0e2a596e690148589d15af21e417d9db_valueheight: 30vh;
	--community1_verticalAlignment: flex-start ;;
	--min-90e50c2813b742208e59a1a07c3b4602_valueheight: unset;
	--90e50c2813b742208e59a1a07c3b4602_valueheight: 100%;
	--90e50c2813b742208e59a1a07c3b4602_verticalAlignment: center ;;

}

section.ctafooter-orientation[custom-agua-item="layer"] {
align-items: var(--horizontalAxis-ctafooter) ;
justify-content: var(--verticalAxis-ctafooter) ;
}
section.featurecards1-orientation[custom-agua-item="layer"] {
align-items: var(--horizontalAxis-featurecards1) ;
justify-content: var(--verticalAxis-featurecards1) ;
}
section.twocolumnscards-orientation[custom-agua-item="layer"] {
align-items: var(--horizontalAxis-twocolumnscards) ;
justify-content: var(--verticalAxis-twocolumnscards) ;
}
section.featurecards5-orientation[custom-agua-item="layer"] {
align-items: var(--horizontalAxis-featurecards5) ;
justify-content: var(--verticalAxis-featurecards5) ;
}
section.layer-658-1f741291c7d04ad498c3accc917ea008-orientation[custom-agua-item="layer"] {
align-items: var(--horizontalAxis-1f741291c7d04ad498c3accc917ea008) ;
justify-content: var(--verticalAxis-1f741291c7d04ad498c3accc917ea008) ;
}
section.layer-164-da6a28df823a420eb39081269d975369[custom-agua-item="layer"] {
width: var(--da6a28df823a420eb39081269d975369_valuewidth);

}
section.layer-251-5b547c317a9c45848f2c97a9a08cadc8[custom-agua-item="layer"] {
width: var(--5b547c317a9c45848f2c97a9a08cadc8_valuewidth);

}
section.layer-178-2f43cbedaf8e4f5481fd0deb94739f6b[custom-agua-item="layer"] {
height: var(--2f43cbedaf8e4f5481fd0deb94739f6b_valueheight);

}
section.layer-178-2f43cbedaf8e4f5481fd0deb94739f6b[custom-agua-item="layer"] {
width: var(--2f43cbedaf8e4f5481fd0deb94739f6b_valuewidth);

}
.imageview-11-1a0531d15e4748e0a10c485b417a4def[custom-agua-item="imageView"] {
width: calc(var(--1a0531d15e4748e0a10c485b417a4def_valuewidth) - calc(var(--1a0531d15e4748e0a10c485b417a4def_margin-left) + var(--1a0531d15e4748e0a10c485b417a4def_margin-right)));

}
.imageview-11-1a0531d15e4748e0a10c485b417a4def[custom-agua-item="imageView"] {
height: var(--1a0531d15e4748e0a10c485b417a4def_valueheight);

}
section.card4[custom-agua-item="layer"] {
width: var(--card4_valuewidth);

}
.imageview-82-aaff49445ae645d48ccd1ad42f638146[custom-agua-item="imageView"] {
width: var(--aaff49445ae645d48ccd1ad42f638146_valuewidth);

}
.imageview-82-aaff49445ae645d48ccd1ad42f638146[custom-agua-item="imageView"] {
height: var(--aaff49445ae645d48ccd1ad42f638146_valueheight);

}
section.layer-233-90e50c2813b742208e59a1a07c3b4602[custom-agua-item="layer"] {
width: calc(var(--90e50c2813b742208e59a1a07c3b4602_valuewidth) - var(--90e50c2813b742208e59a1a07c3b4602_margin-left));

}
section.layer-233-90e50c2813b742208e59a1a07c3b4602[custom-agua-item="layer"] {
height: var(--90e50c2813b742208e59a1a07c3b4602_valueheight);

}
p.text-61-9c9a5ec6a8d445b39b81e2b49d8e0083[custom-agua-item="text"] {
width: var(--9c9a5ec6a8d445b39b81e2b49d8e0083_valuewidth);

}
p.text-117-735fd6031d874acfbe6f6a0b55f534e2[custom-agua-item="text"] {
width: var(--735fd6031d874acfbe6f6a0b55f534e2_valuewidth);

}
}
@media (min-width: 1020px) {main.Screen[custom-agua-item="screen"]{
	--ctafooter_orientation: row;
	--min-joincommunity_margin-left: 30px;
	--joincommunity_margin-left: 30px;
	--min-joincommunity_margin-top: 0px;
	--joincommunity_margin-top: 0px;
	--min-herobgimage_radiusAbsolute: 10px;
	--herobgimage_radiusAbsolute: 10px;
	--min-spacertop_margin-top: 25vh;
	--spacertop_margin-top: 25vh;
	--imagescolumn2_visible: flex;
	--min-6681986d05ac40dca996bc4cb39b0513_valuewidth: 30vw;
	--6681986d05ac40dca996bc4cb39b0513_valuewidth: 30vw;
	--min-6681986d05ac40dca996bc4cb39b0513_valueheight: 30vw;
	--6681986d05ac40dca996bc4cb39b0513_valueheight: 30vw;
	--min-df3e79908bc749d198400b8817fe0281_valuewidth: 30vw;
	--df3e79908bc749d198400b8817fe0281_valuewidth: 30vw;
	--min-df3e79908bc749d198400b8817fe0281_valueheight: 30vw;
	--df3e79908bc749d198400b8817fe0281_valueheight: 30vw;
	--min-fa0e88e0e4f34f26a1259176b11b6835_valuewidth: 30vw;
	--fa0e88e0e4f34f26a1259176b11b6835_valuewidth: 30vw;
	--min-fa0e88e0e4f34f26a1259176b11b6835_valueheight: 30vw;
	--fa0e88e0e4f34f26a1259176b11b6835_valueheight: 30vw;
	--min-4f3b8726448d4691b089489fe07b2cd4_valuewidth: 30vw;
	--4f3b8726448d4691b089489fe07b2cd4_valuewidth: 30vw;
	--min-4f3b8726448d4691b089489fe07b2cd4_valueheight: 30vw;
	--4f3b8726448d4691b089489fe07b2cd4_valueheight: 30vw;
	--min-bb582d4b4f0f4fe1ae6b313f127de88c_valuewidth: 30vw;
	--bb582d4b4f0f4fe1ae6b313f127de88c_valuewidth: 30vw;
	--min-bb582d4b4f0f4fe1ae6b313f127de88c_valueheight: 30vw;
	--bb582d4b4f0f4fe1ae6b313f127de88c_valueheight: 30vw;
	--min-248333696458415c835f3716b0af867e_valuewidth: 30vw;
	--248333696458415c835f3716b0af867e_valuewidth: 30vw;
	--min-248333696458415c835f3716b0af867e_valueheight: 30vw;
	--248333696458415c835f3716b0af867e_valueheight: 30vw;
	--min-19f2eac4867a471d8bcf6d9db117fd2b_valuewidth: 30vw;
	--19f2eac4867a471d8bcf6d9db117fd2b_valuewidth: 30vw;
	--min-19f2eac4867a471d8bcf6d9db117fd2b_valueheight: 30vw;
	--19f2eac4867a471d8bcf6d9db117fd2b_valueheight: 30vw;
	--min-b119136414454c9a876b8e609f19311c_valuewidth: 30vw;
	--b119136414454c9a876b8e609f19311c_valuewidth: 30vw;
	--min-b119136414454c9a876b8e609f19311c_valueheight: 30vw;
	--b119136414454c9a876b8e609f19311c_valueheight: 30vw;
	--min-353d66af51214b3da0e916f7d4267dd1_valueheight: 30vw;
	--353d66af51214b3da0e916f7d4267dd1_valueheight: 30vw;
	--min-353d66af51214b3da0e916f7d4267dd1_valuewidth: 30vw;
	--353d66af51214b3da0e916f7d4267dd1_valuewidth: 30vw;
	--min-76784e55ba824ed69dd31176c073f383_valueheight: 30vw;
	--76784e55ba824ed69dd31176c073f383_valueheight: 30vw;
	--min-76784e55ba824ed69dd31176c073f383_valuewidth: 30vw;
	--76784e55ba824ed69dd31176c073f383_valuewidth: 30vw;
	--min-b5afd3d872764e22b7b09e9c1d4244d6_valueheight: 30vw;
	--b5afd3d872764e22b7b09e9c1d4244d6_valueheight: 30vw;
	--min-b5afd3d872764e22b7b09e9c1d4244d6_valuewidth: 30vw;
	--b5afd3d872764e22b7b09e9c1d4244d6_valuewidth: 30vw;
	--min-79360d610be641a089d3a99b9ec83acc_valueheight: 30vw;
	--79360d610be641a089d3a99b9ec83acc_valueheight: 30vw;
	--min-79360d610be641a089d3a99b9ec83acc_valuewidth: 30vw;
	--79360d610be641a089d3a99b9ec83acc_valuewidth: 30vw;
	--min-bgfooter_valuewidth: 50%;
	--bgfooter_valuewidth: 50%;
	--min-madeincontent_padding-bottom: 275px;
	--madeincontent_padding-bottom: 275px;
	--min-madeincontent_margin-top: 64px;
	--madeincontent_margin-top: 64px;
	--min-stickymadeinagua_margin-top: 0px;
	--stickymadeinagua_margin-top: 0px;
	--min-madeinagua_padding-top: 0px;
	--madeinagua_padding-top: 0px;
	--min-imagefeaturescontainer_valueheight: 50vw;
	--imagefeaturescontainer_valueheight: 50vw;
	--min-imagefeaturescontainer_valuewidth: unset;
	--imagefeaturescontainer_valuewidth: 100%;
	--min-featurescontent_margin-top: 0vw;
	--featurescontent_margin-top: 0vw;
	--min-stickymadeinagua_radiusAbsolute: 50px;
	--stickymadeinagua_radiusAbsolute: 50px;
	--featurecards1_orientation: row;
	--97f49bb246714c2f9a5d623b7d98c3e1_horizontalAlignment: flex-start ;;
	--min-10b1968fafc24af69b91d41fcb7dbf7c_margin-left: 30px;
	--10b1968fafc24af69b91d41fcb7dbf7c_margin-left: 30px;
	--min-10b1968fafc24af69b91d41fcb7dbf7c_margin-right: 30px;
	--10b1968fafc24af69b91d41fcb7dbf7c_margin-right: 30px;
	--min-fa709c781c564ad7a917fd694e87fa0c_margin-left: 30px;
	--fa709c781c564ad7a917fd694e87fa0c_margin-left: 30px;
	--min-fa709c781c564ad7a917fd694e87fa0c_margin-right: 30px;
	--fa709c781c564ad7a917fd694e87fa0c_margin-right: 30px;
	--min-1a0531d15e4748e0a10c485b417a4def_valuewidth: 900px;
	--1a0531d15e4748e0a10c485b417a4def_valuewidth: 900px;
	--min-card3_margin-left: 0px;
	--card3_margin-left: 0px;
	--min-card3_margin-right: 0px;
	--card3_margin-right: 0px;
	--min-featurecards2_padding-top: 0px;
	--featurecards2_padding-top: 0px;
	--min-1a0531d15e4748e0a10c485b417a4def_valueheight: 807px;
	--1a0531d15e4748e0a10c485b417a4def_valueheight: 807px;
	--min-1f493a2b0a644c77bd2c2825a68c29e4_margin-left: 10px;
	--1f493a2b0a644c77bd2c2825a68c29e4_margin-left: 10px;
	--min-1a0531d15e4748e0a10c485b417a4def_margin-left: 0px;
	--1a0531d15e4748e0a10c485b417a4def_margin-left: 0px;
	--min-1a0531d15e4748e0a10c485b417a4def_margin-right: 0px;
	--1a0531d15e4748e0a10c485b417a4def_margin-right: 0px;
	--min-line1card3_margin-top: 0px;
	--line1card3_margin-top: 0px;
	--min-container_padding-bottom: 0px;
	--container_padding-bottom: 0px;
	--min-da6a28df823a420eb39081269d975369_valuewidth: unset;
	--da6a28df823a420eb39081269d975369_valuewidth: 100%;
	--min-5b547c317a9c45848f2c97a9a08cadc8_valueheight: 400px;
	--5b547c317a9c45848f2c97a9a08cadc8_valueheight: 400px;
	--min-5b547c317a9c45848f2c97a9a08cadc8_valuewidth: unset;
	--5b547c317a9c45848f2c97a9a08cadc8_valuewidth: 100%;
	--min-footercontainer_padding-left: 128px;
	--footercontainer_padding-left: 128px;
	--min-footercontainer_padding-right: 128px;
	--footercontainer_padding-right: 128px;
	--min-community1_padding-left: 128px;
	--community1_padding-left: 128px;
	--min-community1_margin-left: 0px;
	--community1_margin-left: 0px;
	--min-9c9a5ec6a8d445b39b81e2b49d8e0083_valuewidth: unset;
	--9c9a5ec6a8d445b39b81e2b49d8e0083_valuewidth: fit-content;
	--min-90e50c2813b742208e59a1a07c3b4602_margin-left: 64px;
	--90e50c2813b742208e59a1a07c3b4602_margin-left: 64px;
	--min-735fd6031d874acfbe6f6a0b55f534e2_valuewidth: unset;
	--735fd6031d874acfbe6f6a0b55f534e2_valuewidth: 100%;
	--min-90e50c2813b742208e59a1a07c3b4602_valuewidth: 770px;
	--90e50c2813b742208e59a1a07c3b4602_valuewidth: 770px;
	--min-community1_padding-right: 128px;
	--community1_padding-right: 128px;
	--min-community1_padding-top: 0px;
	--community1_padding-top: 0px;
	--min-usersloveus_valueheight: 600px;
	--usersloveus_valueheight: 600px;
	--madeinagua_backgroundColor: #000000;
	--433545bdefdd42d991a3af6803271662_visible: flex;
	--ea5a4579043e45f5969fc36cbb468631_visible: flex;
	--min-card4_valuewidth: unset;
	--card4_valuewidth: 100%;
	--card3container_wrap: unset;
	--min-2f43cbedaf8e4f5481fd0deb94739f6b_valueheight: unset;
	--2f43cbedaf8e4f5481fd0deb94739f6b_valueheight: 100%;
	--min-2f43cbedaf8e4f5481fd0deb94739f6b_valuewidth: unset;
	--2f43cbedaf8e4f5481fd0deb94739f6b_valuewidth: 100%;
	--2f43cbedaf8e4f5481fd0deb94739f6b_visible: flex;
	--min-card2menu_margin-top: 0px;
	--card2menu_margin-top: 0px;
	--min-card2menu_margin-bottom: 0px;
	--card2menu_margin-bottom: 0px;
	--card1menu_horizontalAlignment: flex-start ;;
	--card1menu_verticalAlignment: flex-start ;;
	--card2menu_horizontalAlignment: flex-start ;;
	--card2menu_verticalAlignment: flex-start ;;
	--4955e12c96ec4639966207fa2aa6f555_horizontalAlignment: flex-start ;;
	--4955e12c96ec4639966207fa2aa6f555_verticalAlignment: flex-start ;;
	--1f741291c7d04ad498c3accc917ea008_orientation: row;
	--min-d9cd3baba0f44deaa8fa608dd8a896df_valuewidth: 808px;
	--d9cd3baba0f44deaa8fa608dd8a896df_valuewidth: 808px;
	--min-d9cd3baba0f44deaa8fa608dd8a896df_valueheight: 600px;
	--d9cd3baba0f44deaa8fa608dd8a896df_valueheight: 600px;
	--d9cd3baba0f44deaa8fa608dd8a896df_visible: flex;
	--aaff49445ae645d48ccd1ad42f638146_visible: none;
	--min-aaff49445ae645d48ccd1ad42f638146_valuewidth: 808px;
	--aaff49445ae645d48ccd1ad42f638146_valuewidth: 808px;
	--min-aaff49445ae645d48ccd1ad42f638146_valueheight: 600px;
	--aaff49445ae645d48ccd1ad42f638146_valueheight: 600px;
	--min-sponsorstext_valuewidth: 300px;
	--sponsorstext_valuewidth: 300px;
	--min-sponsorstext_margin-top: 0px;
	--sponsorstext_margin-top: 0px;
	--min-1f741291c7d04ad498c3accc917ea008_valueheight: unset;
	--1f741291c7d04ad498c3accc917ea008_valueheight: 100%;
	--min-sponsorscard_valueheight: unset;
	--sponsorscard_valueheight: 100%;
	--min-sponsorstext_margin-left: 0px;
	--sponsorstext_margin-left: 0px;
	--min-sponsorscta_margin-left: 0px;
	--sponsorscta_margin-left: 0px;
	--1f741291c7d04ad498c3accc917ea008_verticalAlignment: flex-start ;;
	--1f741291c7d04ad498c3accc917ea008_horizontalAlignment: space-between ;;
	--min-sponsorscta_margin-bottom: 0px;
	--sponsorscta_margin-bottom: 0px;
	--min-1f741291c7d04ad498c3accc917ea008_radiusAbsolute: 0px;
	--1f741291c7d04ad498c3accc917ea008_radiusAbsolute: 0px;
	--8cfaebf5ef6e43ab9b35060b25738113_text: We are launching our beta!;
	--min-78f69d1d417440e2b88db0edd6cc2325_valuewidth: 35px;
	--78f69d1d417440e2b88db0edd6cc2325_valuewidth: 35px;
	--min-78f69d1d417440e2b88db0edd6cc2325_valueheight: 35px;
	--78f69d1d417440e2b88db0edd6cc2325_valueheight: 35px;
	--min-53ded678cce340be8409af0eda3f5480_padding-left: 64px;
	--53ded678cce340be8409af0eda3f5480_padding-left: 64px;
	--min-53ded678cce340be8409af0eda3f5480_padding-right: 64px;
	--53ded678cce340be8409af0eda3f5480_padding-right: 64px;
	--min-featurecards1_padding-left: 32px;
	--featurecards1_padding-left: 32px;
	--min-card1menu_margin-left: 32px;
	--card1menu_margin-left: 32px;
	--min-card2menu_margin-left: 32px;
	--card2menu_margin-left: 32px;
	--min-card3menu1_margin-left: 32px;
	--card3menu1_margin-left: 32px;
	--min-horizontalcard1_padding-top: 32px;
	--horizontalcard1_padding-top: 32px;
	--min-twocolumnscards_padding-right: 32px;
	--twocolumnscards_padding-right: 32px;
	--min-twocolumnscards_padding-top: 32px;
	--twocolumnscards_padding-top: 32px;
	--min-card8_margin-right: 32px;
	--card8_margin-right: 32px;
	--min-card7_margin-right: 32px;
	--card7_margin-right: 32px;
	--twocolumnscards_orientation: row;
	--min-card8_margin-top: 0px;
	--card8_margin-top: 0px;
	--min-featurecards1_margin-top: 32px;
	--featurecards1_margin-top: 32px;
	--card1menu_backgroundColor: transparent;
	--ea5a4579043e45f5969fc36cbb468631_backgroundColor: transparent;
	--featurecards5_orientation: row;
	--min-81373cfe93294cb5b06833b77c5e617c_margin-right: 32px;
	--81373cfe93294cb5b06833b77c5e617c_margin-right: 32px;
	--min-featurecards5_padding-right: 32px;
	--featurecards5_padding-right: 32px;
	--min-81373cfe93294cb5b06833b77c5e617c_valueheight: 800px;
	--81373cfe93294cb5b06833b77c5e617c_valueheight: 800px;
	--min-0e2a596e690148589d15af21e417d9db_valueheight: unset;
	--0e2a596e690148589d15af21e417d9db_valueheight: 100%;
	--community1_verticalAlignment: center ;;
	--min-90e50c2813b742208e59a1a07c3b4602_valueheight: unset;
	--90e50c2813b742208e59a1a07c3b4602_valueheight: fit-content;
	--90e50c2813b742208e59a1a07c3b4602_verticalAlignment: flex-start ;;

}

section.ctafooter-orientation[custom-agua-item="layer"] {
align-items: var(--verticalAxis-ctafooter) ;
justify-content: var(--horizontalAxis-ctafooter) ;
}
section.featurecards1-orientation[custom-agua-item="layer"] {
align-items: var(--verticalAxis-featurecards1) ;
justify-content: var(--horizontalAxis-featurecards1) ;
}
section.twocolumnscards-orientation[custom-agua-item="layer"] {
align-items: var(--verticalAxis-twocolumnscards) ;
justify-content: var(--horizontalAxis-twocolumnscards) ;
}
section.featurecards5-orientation[custom-agua-item="layer"] {
align-items: var(--verticalAxis-featurecards5) ;
justify-content: var(--horizontalAxis-featurecards5) ;
}
section.layer-658-1f741291c7d04ad498c3accc917ea008-orientation[custom-agua-item="layer"] {
align-items: var(--verticalAxis-1f741291c7d04ad498c3accc917ea008) ;
justify-content: var(--horizontalAxis-1f741291c7d04ad498c3accc917ea008) ;
}
section.imagefeaturescontainer[custom-agua-item="layer"] {
width: var(--imagefeaturescontainer_valuewidth);

}
section.layer-164-da6a28df823a420eb39081269d975369[custom-agua-item="layer"] {
width: var(--da6a28df823a420eb39081269d975369_valuewidth);

}
section.layer-251-5b547c317a9c45848f2c97a9a08cadc8[custom-agua-item="layer"] {
width: var(--5b547c317a9c45848f2c97a9a08cadc8_valuewidth);

}
section.layer-178-2f43cbedaf8e4f5481fd0deb94739f6b[custom-agua-item="layer"] {
height: var(--2f43cbedaf8e4f5481fd0deb94739f6b_valueheight);

}
section.layer-178-2f43cbedaf8e4f5481fd0deb94739f6b[custom-agua-item="layer"] {
width: var(--2f43cbedaf8e4f5481fd0deb94739f6b_valuewidth);

}
section.card4[custom-agua-item="layer"] {
width: var(--card4_valuewidth);

}
section.sponsorscard[custom-agua-item="layer"] {
height: var(--sponsorscard_valueheight);

}
section.layer-658-1f741291c7d04ad498c3accc917ea008[custom-agua-item="layer"] {
height: var(--1f741291c7d04ad498c3accc917ea008_valueheight);

}
section.layer-132-0e2a596e690148589d15af21e417d9db[custom-agua-item="layer"] {
height: calc(var(--0e2a596e690148589d15af21e417d9db_valueheight) - calc(64px + 0px));

}
p.text-117-735fd6031d874acfbe6f6a0b55f534e2[custom-agua-item="text"] {
width: var(--735fd6031d874acfbe6f6a0b55f534e2_valuewidth);

}
}
@media (max-width: 1019px) and (min-width: 481px) {main.Screen[custom-agua-item="screen"] {
	--ctafooter_orientation: row;
	--min-joincommunity_margin-left: 30px;
	--joincommunity_margin-left: 30px;
	--min-joincommunity_margin-top: 0px;
	--joincommunity_margin-top: 0px;
	--min-herobgimage_radiusAbsolute: 10px;
	--herobgimage_radiusAbsolute: 10px;
	--min-spacertop_margin-top: 37vh;
	--spacertop_margin-top: 37vh;
	--imagescolumn2_visible: flex;
	--min-6681986d05ac40dca996bc4cb39b0513_valuewidth: 30vw;
	--6681986d05ac40dca996bc4cb39b0513_valuewidth: 30vw;
	--min-6681986d05ac40dca996bc4cb39b0513_valueheight: 30vw;
	--6681986d05ac40dca996bc4cb39b0513_valueheight: 30vw;
	--min-df3e79908bc749d198400b8817fe0281_valuewidth: 30vw;
	--df3e79908bc749d198400b8817fe0281_valuewidth: 30vw;
	--min-df3e79908bc749d198400b8817fe0281_valueheight: 30vw;
	--df3e79908bc749d198400b8817fe0281_valueheight: 30vw;
	--min-fa0e88e0e4f34f26a1259176b11b6835_valuewidth: 30vw;
	--fa0e88e0e4f34f26a1259176b11b6835_valuewidth: 30vw;
	--min-fa0e88e0e4f34f26a1259176b11b6835_valueheight: 30vw;
	--fa0e88e0e4f34f26a1259176b11b6835_valueheight: 30vw;
	--min-4f3b8726448d4691b089489fe07b2cd4_valuewidth: 30vw;
	--4f3b8726448d4691b089489fe07b2cd4_valuewidth: 30vw;
	--min-4f3b8726448d4691b089489fe07b2cd4_valueheight: 30vw;
	--4f3b8726448d4691b089489fe07b2cd4_valueheight: 30vw;
	--min-bb582d4b4f0f4fe1ae6b313f127de88c_valuewidth: 30vw;
	--bb582d4b4f0f4fe1ae6b313f127de88c_valuewidth: 30vw;
	--min-bb582d4b4f0f4fe1ae6b313f127de88c_valueheight: 30vw;
	--bb582d4b4f0f4fe1ae6b313f127de88c_valueheight: 30vw;
	--min-248333696458415c835f3716b0af867e_valuewidth: 30vw;
	--248333696458415c835f3716b0af867e_valuewidth: 30vw;
	--min-248333696458415c835f3716b0af867e_valueheight: 30vw;
	--248333696458415c835f3716b0af867e_valueheight: 30vw;
	--min-19f2eac4867a471d8bcf6d9db117fd2b_valuewidth: 30vw;
	--19f2eac4867a471d8bcf6d9db117fd2b_valuewidth: 30vw;
	--min-19f2eac4867a471d8bcf6d9db117fd2b_valueheight: 30vw;
	--19f2eac4867a471d8bcf6d9db117fd2b_valueheight: 30vw;
	--min-b119136414454c9a876b8e609f19311c_valuewidth: 30vw;
	--b119136414454c9a876b8e609f19311c_valuewidth: 30vw;
	--min-b119136414454c9a876b8e609f19311c_valueheight: 30vw;
	--b119136414454c9a876b8e609f19311c_valueheight: 30vw;
	--min-353d66af51214b3da0e916f7d4267dd1_valueheight: 30vw;
	--353d66af51214b3da0e916f7d4267dd1_valueheight: 30vw;
	--min-353d66af51214b3da0e916f7d4267dd1_valuewidth: 30vw;
	--353d66af51214b3da0e916f7d4267dd1_valuewidth: 30vw;
	--min-76784e55ba824ed69dd31176c073f383_valueheight: 30vw;
	--76784e55ba824ed69dd31176c073f383_valueheight: 30vw;
	--min-76784e55ba824ed69dd31176c073f383_valuewidth: 30vw;
	--76784e55ba824ed69dd31176c073f383_valuewidth: 30vw;
	--min-b5afd3d872764e22b7b09e9c1d4244d6_valueheight: 30vw;
	--b5afd3d872764e22b7b09e9c1d4244d6_valueheight: 30vw;
	--min-b5afd3d872764e22b7b09e9c1d4244d6_valuewidth: 30vw;
	--b5afd3d872764e22b7b09e9c1d4244d6_valuewidth: 30vw;
	--min-79360d610be641a089d3a99b9ec83acc_valueheight: 30vw;
	--79360d610be641a089d3a99b9ec83acc_valueheight: 30vw;
	--min-79360d610be641a089d3a99b9ec83acc_valuewidth: 30vw;
	--79360d610be641a089d3a99b9ec83acc_valuewidth: 30vw;
	--min-bgfooter_valuewidth: 100%;
	--bgfooter_valuewidth: 100%;
	--min-madeincontent_padding-bottom: 350px;
	--madeincontent_padding-bottom: 350px;
	--min-madeincontent_margin-top: 64px;
	--madeincontent_margin-top: 64px;
	--min-stickymadeinagua_margin-top: 0px;
	--stickymadeinagua_margin-top: 0px;
	--min-madeinagua_padding-top: 0px;
	--madeinagua_padding-top: 0px;
	--min-imagefeaturescontainer_valueheight: 56vw;
	--imagefeaturescontainer_valueheight: 56vw;
	--min-imagefeaturescontainer_valuewidth: 100vw;
	--imagefeaturescontainer_valuewidth: 100vw;
	--min-featurescontent_margin-top: 100px;
	--featurescontent_margin-top: 100px;
	--min-stickymadeinagua_radiusAbsolute: 50px;
	--stickymadeinagua_radiusAbsolute: 50px;
	--featurecards1_orientation: column;
	--97f49bb246714c2f9a5d623b7d98c3e1_horizontalAlignment: flex-start ;;
	--min-10b1968fafc24af69b91d41fcb7dbf7c_margin-left: 30px;
	--10b1968fafc24af69b91d41fcb7dbf7c_margin-left: 30px;
	--min-10b1968fafc24af69b91d41fcb7dbf7c_margin-right: 30px;
	--10b1968fafc24af69b91d41fcb7dbf7c_margin-right: 30px;
	--min-fa709c781c564ad7a917fd694e87fa0c_margin-left: 30px;
	--fa709c781c564ad7a917fd694e87fa0c_margin-left: 30px;
	--min-fa709c781c564ad7a917fd694e87fa0c_margin-right: 30px;
	--fa709c781c564ad7a917fd694e87fa0c_margin-right: 30px;
	--min-1a0531d15e4748e0a10c485b417a4def_valuewidth: unset;
	--1a0531d15e4748e0a10c485b417a4def_valuewidth: 100%;
	--min-card3_margin-left: 0px;
	--card3_margin-left: 0px;
	--min-card3_margin-right: 0px;
	--card3_margin-right: 0px;
	--min-featurecards2_padding-top: 0px;
	--featurecards2_padding-top: 0px;
	--min-1a0531d15e4748e0a10c485b417a4def_valueheight: unset;
	--1a0531d15e4748e0a10c485b417a4def_valueheight: 100%;
	--min-1f493a2b0a644c77bd2c2825a68c29e4_margin-left: 10px;
	--1f493a2b0a644c77bd2c2825a68c29e4_margin-left: 10px;
	--min-1a0531d15e4748e0a10c485b417a4def_margin-left: 0px;
	--1a0531d15e4748e0a10c485b417a4def_margin-left: 0px;
	--min-1a0531d15e4748e0a10c485b417a4def_margin-right: 0px;
	--1a0531d15e4748e0a10c485b417a4def_margin-right: 0px;
	--min-line1card3_margin-top: 0px;
	--line1card3_margin-top: 0px;
	--min-container_padding-bottom: 0px;
	--container_padding-bottom: 0px;
	--min-da6a28df823a420eb39081269d975369_valuewidth: 588px;
	--da6a28df823a420eb39081269d975369_valuewidth: 588px;
	--min-5b547c317a9c45848f2c97a9a08cadc8_valueheight: 400px;
	--5b547c317a9c45848f2c97a9a08cadc8_valueheight: 400px;
	--min-5b547c317a9c45848f2c97a9a08cadc8_valuewidth: 588px;
	--5b547c317a9c45848f2c97a9a08cadc8_valuewidth: 588px;
	--min-footercontainer_padding-left: 128px;
	--footercontainer_padding-left: 128px;
	--min-footercontainer_padding-right: 128px;
	--footercontainer_padding-right: 128px;
	--min-community1_padding-left: 0px;
	--community1_padding-left: 0px;
	--min-community1_margin-left: 32px;
	--community1_margin-left: 32px;
	--min-9c9a5ec6a8d445b39b81e2b49d8e0083_valuewidth: unset;
	--9c9a5ec6a8d445b39b81e2b49d8e0083_valuewidth: 100%;
	--min-90e50c2813b742208e59a1a07c3b4602_margin-left: 32px;
	--90e50c2813b742208e59a1a07c3b4602_margin-left: 32px;
	--min-735fd6031d874acfbe6f6a0b55f534e2_valuewidth: unset;
	--735fd6031d874acfbe6f6a0b55f534e2_valuewidth: 100%;
	--min-90e50c2813b742208e59a1a07c3b4602_valuewidth: unset;
	--90e50c2813b742208e59a1a07c3b4602_valuewidth: 100%;
	--min-community1_padding-right: 0px;
	--community1_padding-right: 0px;
	--min-community1_padding-top: 128px;
	--community1_padding-top: 128px;
	--min-usersloveus_valueheight: 80vh;
	--usersloveus_valueheight: 80vh;
	--madeinagua_backgroundColor: #000000;
	--433545bdefdd42d991a3af6803271662_visible: flex;
	--ea5a4579043e45f5969fc36cbb468631_visible: flex;
	--min-card4_valuewidth: unset;
	--card4_valuewidth: 100%;
	--card3container_wrap: wrap;
	--min-2f43cbedaf8e4f5481fd0deb94739f6b_valueheight: unset;
	--2f43cbedaf8e4f5481fd0deb94739f6b_valueheight: 100%;
	--min-2f43cbedaf8e4f5481fd0deb94739f6b_valuewidth: unset;
	--2f43cbedaf8e4f5481fd0deb94739f6b_valuewidth: 100%;
	--2f43cbedaf8e4f5481fd0deb94739f6b_visible: flex;
	--min-card2menu_margin-top: 16px;
	--card2menu_margin-top: 16px;
	--min-card2menu_margin-bottom: 16px;
	--card2menu_margin-bottom: 16px;
	--card1menu_horizontalAlignment: center ;;
	--card1menu_verticalAlignment: center ;;
	--card2menu_horizontalAlignment: center ;;
	--card2menu_verticalAlignment: center ;;
	--4955e12c96ec4639966207fa2aa6f555_horizontalAlignment: center ;;
	--4955e12c96ec4639966207fa2aa6f555_verticalAlignment: center ;;
	--1f741291c7d04ad498c3accc917ea008_orientation: column;
	--min-d9cd3baba0f44deaa8fa608dd8a896df_valuewidth: unset;
	--d9cd3baba0f44deaa8fa608dd8a896df_valuewidth: 100%;
	--min-d9cd3baba0f44deaa8fa608dd8a896df_valueheight: unset;
	--d9cd3baba0f44deaa8fa608dd8a896df_valueheight: 100%;
	--d9cd3baba0f44deaa8fa608dd8a896df_visible: none;
	--aaff49445ae645d48ccd1ad42f638146_visible: flex;
	--min-aaff49445ae645d48ccd1ad42f638146_valuewidth: unset;
	--aaff49445ae645d48ccd1ad42f638146_valuewidth: 100%;
	--min-aaff49445ae645d48ccd1ad42f638146_valueheight: unset;
	--aaff49445ae645d48ccd1ad42f638146_valueheight: 100%;
	--min-sponsorstext_valuewidth: unset;
	--sponsorstext_valuewidth: 100%;
	--min-sponsorstext_margin-top: 32px;
	--sponsorstext_margin-top: 32px;
	--min-1f741291c7d04ad498c3accc917ea008_valueheight: unset;
	--1f741291c7d04ad498c3accc917ea008_valueheight: 100%;
	--min-sponsorscard_valueheight: unset;
	--sponsorscard_valueheight: 100%;
	--min-sponsorstext_margin-left: 12px;
	--sponsorstext_margin-left: 12px;
	--min-sponsorscta_margin-left: 12px;
	--sponsorscta_margin-left: 12px;
	--1f741291c7d04ad498c3accc917ea008_verticalAlignment: flex-start ;;
	--1f741291c7d04ad498c3accc917ea008_horizontalAlignment: space-between ;;
	--min-sponsorscta_margin-bottom: 32px;
	--sponsorscta_margin-bottom: 32px;
	--min-1f741291c7d04ad498c3accc917ea008_radiusAbsolute: 0px;
	--1f741291c7d04ad498c3accc917ea008_radiusAbsolute: 0px;
	--8cfaebf5ef6e43ab9b35060b25738113_text: We are launching our beta!;
	--min-78f69d1d417440e2b88db0edd6cc2325_valuewidth: 35px;
	--78f69d1d417440e2b88db0edd6cc2325_valuewidth: 35px;
	--min-78f69d1d417440e2b88db0edd6cc2325_valueheight: 35px;
	--78f69d1d417440e2b88db0edd6cc2325_valueheight: 35px;
	--min-53ded678cce340be8409af0eda3f5480_padding-left: 64px;
	--53ded678cce340be8409af0eda3f5480_padding-left: 64px;
	--min-53ded678cce340be8409af0eda3f5480_padding-right: 64px;
	--53ded678cce340be8409af0eda3f5480_padding-right: 64px;
	--min-featurecards1_padding-left: 0px;
	--featurecards1_padding-left: 0px;
	--min-card1menu_margin-left: 16px;
	--card1menu_margin-left: 16px;
	--min-card2menu_margin-left: 16px;
	--card2menu_margin-left: 16px;
	--min-card3menu1_margin-left: 16px;
	--card3menu1_margin-left: 16px;
	--min-horizontalcard1_padding-top: 16px;
	--horizontalcard1_padding-top: 16px;
	--min-twocolumnscards_padding-right: 0px;
	--twocolumnscards_padding-right: 0px;
	--min-twocolumnscards_padding-top: 16px;
	--twocolumnscards_padding-top: 16px;
	--min-card8_margin-right: 16px;
	--card8_margin-right: 16px;
	--min-card7_margin-right: 16px;
	--card7_margin-right: 16px;
	--twocolumnscards_orientation: column;
	--min-card8_margin-top: 16px;
	--card8_margin-top: 16px;
	--min-featurecards1_margin-top: 16px;
	--featurecards1_margin-top: 16px;
	--card1menu_backgroundColor: transparent;
	--ea5a4579043e45f5969fc36cbb468631_backgroundColor: transparent;
	--featurecards5_orientation: column;
	--min-81373cfe93294cb5b06833b77c5e617c_margin-right: 0px;
	--81373cfe93294cb5b06833b77c5e617c_margin-right: 0px;
	--min-featurecards5_padding-right: 16px;
	--featurecards5_padding-right: 16px;
	--min-81373cfe93294cb5b06833b77c5e617c_valueheight: 800px;
	--81373cfe93294cb5b06833b77c5e617c_valueheight: 800px;
	--min-0e2a596e690148589d15af21e417d9db_valueheight: unset;
	--0e2a596e690148589d15af21e417d9db_valueheight: 100%;
	--community1_verticalAlignment: center ;;
	--min-90e50c2813b742208e59a1a07c3b4602_valueheight: unset;
	--90e50c2813b742208e59a1a07c3b4602_valueheight: fit-content;
	--90e50c2813b742208e59a1a07c3b4602_verticalAlignment: flex-start ;;

}

section.ctafooter-orientation[custom-agua-item="layer"] {
align-items: var(--verticalAxis-ctafooter) ;
justify-content: var(--horizontalAxis-ctafooter) ;
}
section.featurecards1-orientation[custom-agua-item="layer"] {
align-items: var(--horizontalAxis-featurecards1) ;
justify-content: var(--verticalAxis-featurecards1) ;
}
section.twocolumnscards-orientation[custom-agua-item="layer"] {
align-items: var(--horizontalAxis-twocolumnscards) ;
justify-content: var(--verticalAxis-twocolumnscards) ;
}
section.featurecards5-orientation[custom-agua-item="layer"] {
align-items: var(--horizontalAxis-featurecards5) ;
justify-content: var(--verticalAxis-featurecards5) ;
}
section.layer-658-1f741291c7d04ad498c3accc917ea008-orientation[custom-agua-item="layer"] {
align-items: var(--horizontalAxis-1f741291c7d04ad498c3accc917ea008) ;
justify-content: var(--verticalAxis-1f741291c7d04ad498c3accc917ea008) ;
}
section.layer-178-2f43cbedaf8e4f5481fd0deb94739f6b[custom-agua-item="layer"] {
height: var(--2f43cbedaf8e4f5481fd0deb94739f6b_valueheight);

}
section.layer-178-2f43cbedaf8e4f5481fd0deb94739f6b[custom-agua-item="layer"] {
width: var(--2f43cbedaf8e4f5481fd0deb94739f6b_valuewidth);

}
.imageview-11-1a0531d15e4748e0a10c485b417a4def[custom-agua-item="imageView"] {
width: calc(var(--1a0531d15e4748e0a10c485b417a4def_valuewidth) - calc(var(--1a0531d15e4748e0a10c485b417a4def_margin-left) + var(--1a0531d15e4748e0a10c485b417a4def_margin-right)));

}
.imageview-11-1a0531d15e4748e0a10c485b417a4def[custom-agua-item="imageView"] {
height: var(--1a0531d15e4748e0a10c485b417a4def_valueheight);

}
section.card4[custom-agua-item="layer"] {
width: var(--card4_valuewidth);

}
section.sponsorscard[custom-agua-item="layer"] {
height: var(--sponsorscard_valueheight);

}
section.layer-658-1f741291c7d04ad498c3accc917ea008[custom-agua-item="layer"] {
height: var(--1f741291c7d04ad498c3accc917ea008_valueheight);

}
.imageview-82-aaff49445ae645d48ccd1ad42f638146[custom-agua-item="imageView"] {
width: var(--aaff49445ae645d48ccd1ad42f638146_valuewidth);

}
.imageview-82-aaff49445ae645d48ccd1ad42f638146[custom-agua-item="imageView"] {
height: var(--aaff49445ae645d48ccd1ad42f638146_valueheight);

}
section.sponsorstext[custom-agua-item="layer"] {
width: calc(var(--sponsorstext_valuewidth) - var(--sponsorstext_margin-left));

}
.imageview-57-d9cd3baba0f44deaa8fa608dd8a896df[custom-agua-item="imageView"] {
width: var(--d9cd3baba0f44deaa8fa608dd8a896df_valuewidth);

}
.imageview-57-d9cd3baba0f44deaa8fa608dd8a896df[custom-agua-item="imageView"] {
height: var(--d9cd3baba0f44deaa8fa608dd8a896df_valueheight);

}
section.layer-132-0e2a596e690148589d15af21e417d9db[custom-agua-item="layer"] {
height: calc(var(--0e2a596e690148589d15af21e417d9db_valueheight) - calc(64px + 0px));

}
section.layer-233-90e50c2813b742208e59a1a07c3b4602[custom-agua-item="layer"] {
width: calc(var(--90e50c2813b742208e59a1a07c3b4602_valuewidth) - var(--90e50c2813b742208e59a1a07c3b4602_margin-left));

}
p.text-61-9c9a5ec6a8d445b39b81e2b49d8e0083[custom-agua-item="text"] {
width: var(--9c9a5ec6a8d445b39b81e2b49d8e0083_valuewidth);

}
p.text-117-735fd6031d874acfbe6f6a0b55f534e2[custom-agua-item="text"] {
width: var(--735fd6031d874acfbe6f6a0b55f534e2_valuewidth);

}
}