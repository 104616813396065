 
            
body, html, #root {
    width: 100%;
    min-width: 100%;
    height: 100%;
    min-height: 100%;
    display: flex;
    margin: 0;
    font-family:  -apple-system, BlinkMacSystemFont, "Segoe UI", "Noto Sans",
    Helvetica, Roboto, Oxygen, Arial, "Apple Color Emoji", "Segoe UI Emoji",
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

main[custom-agua-item="screen"] {
    display: flex;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background-color: #ffffff;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

section[custom-agua-item="layer"],
div[custom-agua-item="layer"],
nav[custom-agua-item="layer"],
header[custom-agua-item="layer"],
footer[custom-agua-item="layer"],
article[custom-agua-item="layer"],
aside[custom-agua-item="layer"],
button[custom-agua-item="layer"],
form[custom-agua-item="layer"] {
    display: flex;
    position: static;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    scrollbar-width: none;
    -ms-overflow-style: none;
    border-width: 0px;
    background: transparent;
}

p[custom-agua-item="text"],
h1[custom-agua-item="text"],
h2[custom-agua-item="text"],
h3[custom-agua-item="text"],
h4[custom-agua-item="text"],
h5[custom-agua-item="text"],
h6[custom-agua-item="text"],
a[custom-agua-item="text"],
ol[custom-agua-item="text"],
ul[custom-agua-item="text"] {
    padding: 0;
    margin: 0;
    text-decoration: none;
    box-sizing: border-box;
    font-family: -apple-system, system-ui, BlinkMacSystemFont, "Roboto", sans-serif;
    font-size: 18px;
    background: #000000;
    height: fit-content;
    width: fit-content;
    background-clip: text;
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent;
}

img[custom-agua-item="imageView"] {
    box-sizing: border-box;
    object-fit: contain;
    height: 90px;
    min-height: 90px;
    width: 115px;
    min-width: 115px;
}

span[custom-agua-item="icon"] {
    box-sizing: border-box;
    background-color: #000000;
    height: fit-content;
    width: fit-content;
    font-family: 'Material Symbols Rounded';
    background-clip: text;
    -webkit-background-clip: text  !important;
    -webkit-text-fill-color: transparent;
    font-weight: normal;
    font-style: normal;
    font-size: 30px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
    font-variation-settings:
    'FILL' 0,
    'wght' 400,
    'GRAD' 0,
    'opsz' 24
}

:root {
	--bgColor: #05050c;
	--bgMainImage: url(assets/bgMain.png);
	--fontTitleColor: #9E9E9E;
	--min-fontSmallTitleSize: 14px;
	--fontSmallTitleSize: 14px;
	--gradientColor1: linear-gradient(50deg,#714dff,#9c83ff 31.28%,#e151ff 77.97%,#fff759 95.64%);
	--cardColor: #0c0f13;
	--bgColor2: #0c0f13;
	--min-fontSizeRegular: 20px;
	--fontSizeRegular: 20px;
	--cardColor2: #0c0f13;
	--min-smallCardHeight: 400px;
	--smallCardHeight: 400px;
	--min-halfMarginSidesContent: 32px;
	--halfMarginSidesContent: 32px;

}

@media (max-width: 480px) {
:root{	--min-fontTitleSize: 30px;
	--fontTitleSize: 30px;
	--min-fontTitleLarge: 30px;
	--fontTitleLarge: 30px;
	--min-fontTitleXLargeSize: 30px;
	--fontTitleXLargeSize: 30px;
	--min-fontMediumTitleSize: 20px;
	--fontMediumTitleSize: 20px;
	--min-marginSidesContent: 12px;
	--marginSidesContent: 12px;
	--min-smallCardWidth: 300px;
	--smallCardWidth: 300px;
}
}
@media (min-width: 1020px) {
:root{	--min-fontTitleSize: 110px;
	--fontTitleSize: 110px;
	--min-fontTitleLarge: 60px;
	--fontTitleLarge: 60px;
	--min-fontMediumTitleSize: 30px;
	--fontMediumTitleSize: 30px;
	--min-fontTitleXLargeSize: 60px;
	--fontTitleXLargeSize: 60px;
	--min-marginSidesContent: 64px;
	--marginSidesContent: 64px;
	--min-smallCardWidth: 400px;
	--smallCardWidth: 400px;
}
}
@media (max-width: 1019px) and (min-width: 481px) {
:root{	--min-fontTitleSize: 80px;
	--fontTitleSize: 80px;
	--min-fontTitleLarge: 60px;
	--fontTitleLarge: 60px;
	--min-fontMediumTitleSize: 30px;
	--fontMediumTitleSize: 30px;
	--min-fontTitleXLargeSize: 45px;
	--fontTitleXLargeSize: 45px;
	--min-marginSidesContent: 16px;
	--marginSidesContent: 16px;
	--min-smallCardWidth: 600px;
	--smallCardWidth: 600px;
}
}